import React from "react";
import { Modal, Button } from 'antd';
import { useNavigate } from "react-router-dom";
import Auth from "../services/Auth";

export const ModalAccessDenied = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" onClick={() => props.setShowModal(false)}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/AccessDenied.png"} alt="Access Denied"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Oops! Access Denied</div>
                    <div>We were sorry that you couldn't access the feature</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalFailedLogin = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" onClick={() => props.setShowModal(false)}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/FailedLogin.png"} alt="Failed Login"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Oops! Login Failed</div>
                    <div>{props.errorMessage !== "Unauthorised" ? props.errorMessage : "Wrong Email/Password! Try again or click Forgot password to reset it."}</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalFailedRegister = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => props.setShowModal(false)}>
                        I'm Sure
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => props.setShowModal(false)}>
                        Check Again
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/FailedRegister.png"} alt="Failed Register"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Please check your data again</div>
                    <div>Please make sure your email is correct before submit the registration</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalFailedForgotPass = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" onClick={() => props.setShowModal(false)}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/FailedRegister.png"} alt="Failed ForgotPass"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Failed!</div>
                    <div>{props?.message}</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalSuccessRegister = (props) => {
    let navigate = useNavigate();

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }}
                        onClick={() => {
                            props.setShowModal(false);
                            navigate("/login")
                        }}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/EmailSent.png"} alt="Success Register"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Whoosh</div>
                    <div>Please check your email, we were sent you the link to verify your account</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalSuccessReset = (props) => {
    let navigate = useNavigate();

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }}
                        onClick={() => {
                            props.setShowModal(false);
                            navigate("/login")
                        }}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/EmailSent.png"} alt="Success Register"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Success</div>
                    <div>Password has been successfully changed.</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalSuccessChangePass = (props) => {
    // let navigate = useNavigate();

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }}
                        onClick={() => {
                            props.setShowModal(false);
                            Auth.logout()
                        }}>
                        OK
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/EmailSent.png"} alt="Success Register"
                        style={{ cursor: "pointer", width: 200 }}
                    />
                    <div style={{ fontWeight: "bold" }}>Success</div>
                    <div>Password has been successfully changed.</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalServerError = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" onClick={() => props.setShowModal(false)}>
                        Try Again
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/FailedSystem.png"} alt="Failed System" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Oh No! System Error</div>
                    <div>We were sorry for you, but you can try again later</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalLogout = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="back" type="primary"
                        style={{ width: "40%" }}
                        onClick={() => {
                            props.logout();
                            props.setShowModal(false)
                        }}>
                        Yes, Sure
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary"
                        style={{ width: "40%" }}
                        onClick={() => {
                            props.setShowModal(false)
                        }}>
                        No, I'm Not
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/ModalLogout.png"} alt="Logout" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Are you sure want to logout?</div>
                    <div>We will miss you if you want to logout from this account</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalLeaveTask = (props) => {
    let navigate = useNavigate();

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => navigate(`/subject/${props?.subjectID}/adaptive-learning`)}>
                        Back to ADL List
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => props.setShowModal(false)}>
                        {props.study ? "Continue Study" : "Continue Answer"}
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/TaskFailed.png"} alt="Failed System" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Oops!</div>
                    <div> {props.study ? "Are you sure leave the study?" : "Are you sure leave before answer all question?"}</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalTaskEmpty = (props) => {

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-primary" key="submit" type="primary" onClick={() => props.setShowModal(false)}>
                        Ok, Let's do it!
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/TaskFailed.png"} alt="Failed System" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Oops!</div>
                    <div>You need to answer all question before submitting</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalTaskSuccess = (props) => {
    let nextLevel = props.response?.data?.next_tingkat_kesulitan === "sedang" ? "Medium Level" : "Hard Level"
    let navigate = useNavigate();

    const handleNextLevel = () => {
        props.setShowModal(false)
        navigate(`/subject/${props.dataSubject?.id}/adaptive-learning/${props.response?.data?.next_paket_soal_id}`)
    }

    const handleLater = () => {
        props.setShowModal(false)
        navigate(`/subject/${props.dataSubject?.id}/adaptive-learning`)
    }

    const handleCheckStudy = () => {
        props.setShowModal(false)
        navigate(`/subject/${props.dataSubject?.id}/adaptive-learning/${props.response?.data?.next_paket_soal_id}/study`)
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => handleLater()}>
                        Later
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => handleNextLevel()}>
                        Ok, Let's do it!
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/TaskSuccess.png"} alt="Failed System" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Congratulations!</div>
                    <div>Your Score</div>
                    <div className="badge skor">{`${props.response?.data?.score}/${props.dataPaketSoal?.list_soal?.length}`}</div>
                    <div>
                        You're success to continue to the &nbsp;
                        <span style={{ color: "#024102", fontWeight: "bold" }}>{nextLevel}</span>
                    </div>
                    <div style={{ color: "#E98A15", marginTop: 10, cursor: "pointer" }} onClick={() => { handleCheckStudy() }}>Check the study</div>
                </div>
            </Modal>
        </div>
    );
}

export const ModalTaskFailed = (props) => {
    let navigate = useNavigate();

    const handleLater = () => {
        props.setShowModal(false)
        navigate(`/subject/${props.dataSubject?.id}/adaptive-learning`)
    }

    const handleCheckStudy = () => {
        props.setShowModal(false)
        navigate(`/subject/${props.dataSubject?.id}/adaptive-learning/${props.response?.data?.next_paket_soal_id}/study`)
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => handleLater()}>
                        Later
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => props.setShowModal(false)}>
                        Try Again!
                    </Button>
                ]}
            >
                <div style={{ display: "block", textAlign: "center" }}>
                    <img src={"/image/TaskFailed.png"} alt="Failed System" style={{ width: 200 }} />
                    <div style={{ fontWeight: "bold" }}>Oops!</div>
                    <div>Your Score</div>
                    <div className="badge skor">{`${props.response?.data?.score}/${props.dataPaketSoal?.list_soal?.length}`}</div>
                    <div>
                        We're sorry you will not move to the next level
                    </div>
                    <div style={{ color: "#E98A15", marginTop: 10, cursor: "pointer" }} onClick={() => { handleCheckStudy() }}>Check the study</div>
                </div>
            </Modal>
        </div>
    );
}