import React, { useState } from "react";
import {
    useNavigate,
    useLocation
} from "react-router-dom";
import useWindowDimensions from "../utils/size-window";
import { ModalAccessDenied } from "./Modal";

let profile = "/image/Profile.png";

export const Navbar = () => {
    const dimension = useWindowDimensions();
    const navigate = useNavigate();
    const location = useLocation();

    const data = JSON.parse(localStorage.getItem('data'));
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <ModalAccessDenied visible={showModal} setShowModal={setShowModal} />
            {dimension.width > 1210 ?
                <header className="topbar">
                    <div style={{ display: "flex", alignItems: "center", paddingLeft: 20 }}>
                        <img src={"/image/logo-alirsyad.png"} alt="Profile Icon" width={100} height={60}
                            style={{ cursor: "pointer", marginRight: 10, borderRadius: "50%" }}
                            onClick={() => { navigate("/profile") }}
                        />
                        <div style={{ color: "#FFF", fontSize: 20, fontWeight: "bold" }}>
                            Al-Irsyad <span style={{ color: "#FFC727" }}>EDU.</span>
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div onClick={() => { navigate("/") }}
                            className={location.pathname === "/" ? "navmenu active" : "navmenu"}
                        >
                            Home
                        </div>
                        <div onClick={() => { navigate("/subject") }}
                            className={location.pathname.includes("/subject") ? "navmenu active" : "navmenu"}
                        >
                            Subject
                        </div>
                        <div
                            onClick={() => {
                                if (data?.is_pengunjung) {
                                    setShowModal(true)
                                } else {
                                    navigate("/e-report");
                                    localStorage.clearItem('mapel_selected')
                                }
                            }}
                            className={location.pathname.includes("/e-report") ? "navmenu active" : "navmenu"}
                        >
                            E-Report
                        </div>
                        <div
                            onClick={() => {
                                if (data?.is_pengunjung) {
                                    setShowModal(true)
                                } else {
                                    navigate("/achievement")
                                }
                            }}
                            className={location.pathname === "/achievement" ? "navmenu active" : "navmenu"}
                        >
                            Achieve
                        </div>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                        <div style={{ display: "flex", marginRight: 20, alignItems: "center"}} className={`badge ${data?.jenjang.toLowerCase()}`}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>

                        <div style={{ display: "flex", borderLeft: "solid 1px #FFF", paddingLeft: 20 }}>
                            <img src={data?.photo || profile} alt="Profile Icon" width={60} height={60}
                                style={{ cursor: "pointer", marginRight: 10, borderRadius: "50%", objectFit: "cover" }}
                                onClick={() => { navigate("/profile") }}
                            />
                            <div>
                                <span style={{ display: "block", color: "#FFF", fontSize: 18 }}>
                                    Assalamu'alaikum
                                </span>
                                <span style={{ display: "block", color: "#FFF", fontSize: 20, fontWeight: "bold" }}>
                                    {data?.name}
                                </span>
                            </div>
                        </div>
                    </div>
                </header>
                :
                <header className="topbar">
                    <div style={{ display: "flex", paddingLeft: 20 }}>
                        <img src={data?.photo || profile} alt="Profile Icon" width={60} height={60}
                            style={{ cursor: "pointer", marginRight: 10, borderRadius: "50%", objectFit: "cover" }}
                            onClick={() => { navigate("/profile") }}
                        />
                        <div>
                            <span style={{ display: "block", color: "#FFF", fontSize: "1rem" }}>
                                Assalamu'alaikum
                            </span>
                            <span style={{ display: "block", color: "#FFF", fontSize: "1.1rem", fontWeight: "bold" }}>
                                {data?.name}
                            </span>
                        </div>
                    </div>

                    <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20, paddingRight: 20 }}>
                        {data?.is_pengunjung ?
                            <span>
                                Visitor
                            </span>
                            :
                            <span>
                                Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                            </span>
                        }
                    </div>
                </header>
            }
        </>
    )
}