/*
    Aunthetication Created in Client Side (Didn't Follow Best Practice, It Should be REST API with HTTP Call to Server Side)
*/

export default class Auth {
    static logout() {
        localStorage.clear();
        window.location.reload();
    }

    static isLogin() {
        console.log(!!localStorage.getItem('data') && !!localStorage.getItem('token_siswa'))
        return !!localStorage.getItem('data') && !!localStorage.getItem('token_siswa');
    }
}