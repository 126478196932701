import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from 'formik';
import { Layout, Row, Col, Input, Button } from 'antd';

import TopBar from "../components/TopBar";
import { APIServices } from "../services";
import useWindowDimensions from "../utils/size-window";
import { ModalFailedLogin, ModalServerError } from "../components/Modal";

const { Content } = Layout;

const Login = () => {
    let navigate = useNavigate();
    const dimension = useWindowDimensions();
    const [loginAs, setLoginAs] = useState("Student");

    const [loading, setLoading] = useState(false)

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const handleLoginAs = (role) => {
        setLoginAs(role);
    }

    const handleLogin = (body) => {
        setLoading(true)
        APIServices.login(body)
            .then(res => {
                let response = res.data;
                if (response) {
                    localStorage.setItem('data', JSON.stringify(response.data));
                    localStorage.setItem('token_siswa', JSON.stringify(response.data.token));
                    navigate("/");
                    window.location.reload();
                }
                setLoading(false)
            })
            .catch(err => {
                if(err?.request?.status === 500){
                    setShowModal2(true);
                } else {
                    setErrorMessage(err?.response?.data?.data?.error)
                    setShowModal(true);
                }
                console.log(err)
                
                setLoading(false)
            })
    }

    useEffect(() => {
        let data = JSON.parse(localStorage.getItem('data'))
        if(data){
            navigate("/")
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout className="layout">
            <ModalFailedLogin visible={showModal} setShowModal={setShowModal} errorMessage={errorMessage}/>
            <ModalServerError visible={showModal2} setShowModal={setShowModal2}/>
            <Row justify="center" align="middle">
                <Col span={dimension.width>800 ? 12 : 24} style={{ width: "100%", height: "100vh", background: "#FFF" }}>
                    <TopBar />
                    <Content>
                        <Row justify="center" align="middle" style={{ marginBottom: 5, marginTop: 20 }}>
                            <span style={{ textAlign: "center", color: "#024102", fontSize: "1.3rem", fontWeight: "bold" }}>
                                Assalamu'alaikum
                            </span>
                        </Row>
                        <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                            <span style={{ textAlign: "center", color: "#000", fontSize: "1rem" }}>
                                Please fill this form to continue Login <b>as {loginAs}</b>
                            </span>
                        </Row>
                        <Row justify="center" align="middle" style={{ marginBottom: 30 }}>
                            <span className={`nav-login ${(loginAs === "Student") ? "active" : ""}`} onClick={() => handleLoginAs("Student")}>
                                As Student
                            </span>
                            <span className={`nav-login ${(loginAs === "Visitor") ? "active" : ""}`} onClick={() => handleLoginAs("Visitor")}>
                                As Visitor
                            </span>
                        </Row>
                        <Row justify="center">
                            <div style={{ minWidth: "80%" }}>
                                <Formik
                                    initialValues={{ nis: null, password: null }}
                                    validate={values => {
                                        const errors = {};
                                        if (!values.nis) {
                                            errors.nis = (loginAs === "Student") ? 'NIS is required!' : 'Email is required';
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.nis)) {
                                            if((loginAs === "Visitor")){
                                                errors.nis =  'Wrong Email Format!';
                                            }
                                        }
    
                                        if (!values.password) {
                                            errors.password = 'Password is required!';
                                        }
                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        values.role = (loginAs === "Student") ? "student" : "visitor";
                                        setTimeout(() => {
                                            console.log(values)
                                            handleLogin(values)
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({ isSubmitting, values, setFieldValue, errors, validateField, handleSubmit }) => (
                                        <Form>
                                            {(loginAs === "Student") ?
                                                <Row style={{ marginBottom: 10 }}>
                                                    <span style={{ fontWeight: "bold" }}>NIS</span>
                                                    <Input
                                                        // prefix={<MailOutlined />}
                                                        type="text"
                                                        placeholder="Type your NIS"
                                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                        name="nis"
                                                        onChange={(e) => { setFieldValue("nis", e.target.value, true) }}
                                                        status={errors.nis && "error"}
                                                    />
                                                    <ErrorMessage name="nis" component="div" style={{color:"red"}} />
                                                </Row>
                                                :
                                                <Row style={{ marginBottom: 10 }}>
                                                    <span style={{ fontWeight: "bold" }}>Email</span>
                                                    <Input
                                                        // prefix={<MailOutlined />}
                                                        type="text"
                                                        placeholder="Type your Email"
                                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                        name="nis"
                                                        onChange={(e) => { setFieldValue("nis", e.target.value, true) }}
                                                        status={errors.nis && "error"}
                                                    />
                                                    <ErrorMessage name="nis" component="div" style={{color:"red"}}/>
                                                </Row>
                                            }
                                            <Row style={{ marginBottom: 5 }}>
                                                <span style={{ fontWeight: "bold" }}>Password</span>
                                                <Input.Password
                                                    // prefix={<LockOutlined />}
                                                    type="password"
                                                    placeholder="Type your Password"
                                                    style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                    name="password"
                                                    onChange={(e) => { setFieldValue("password", e.target.value, true) }}
                                                    onKeyPress={(e) => {
                                                        if (e.key === "Enter"){
                                                            handleSubmit();
                                                        }
                                                      }}
                                                    status={errors.password && "error"}
                                                />
                                                <ErrorMessage name="password" component="div" style={{color:"red"}}/>
                                            </Row>

                                            <span style={{ cursor: "pointer", color: "#024012", display: "flex", justifyContent: "end", marginBottom: 20 }}
                                                onClick={() => (loginAs === "Student") ? navigate("/forgot-password-student") : navigate("/forgot-password-visitor")}
                                            >
                                                Forgot Password?
                                            </span>

                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                <Button className="button-primary" type="submit" disabled={loading} loading={loading} onClick={handleSubmit}>
                                                    Login
                                                </Button>
                                            </div>

                                            <div style={{ maxWidth: "100%", display: "flex", marginBottom: 10 }}>
                                                <hr />
                                                <span style={{ padding: 5 }}>or</span>
                                                <hr />
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "space-between" }} 
                                                onClick={() => navigate("/register-visitor")}>
                                                <button className="button-secondary" type="button" disabled={isSubmitting}>
                                                    Register as Visitor
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Row>
                    </Content>
                </Col>
                <Col span={12} className="desktop-only" style={{ width: "100%", height: "100vh", background: "#024102" }}>
                    <div style={{display:"flex", height:"100vh", alignItems:"center"}}>
                        <img src="/image/login_bg.png" alt="background login" width="100%">
                        </img>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default (Login)