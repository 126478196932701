import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Radio, Image } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";
import { ModalLeaveTask, ModalTaskEmpty, ModalTaskFailed, ModalTaskSuccess } from "../components/Modal";
import { Markup } from "interweave";
import { MathJaxContext } from "better-react-mathjax";

import { Formik, Form, ErrorMessage } from 'formik';

const AdaptiveLearningDetail = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    let widthValue = (dimension.width > 800) ? dimension.width - 175 : dimension.width - 95

    const [loading, setLoading] = useState(true);

    const [SBClicked, setSBClicked] = useState(false);

    const [showModal, setShowModal] = useState(false);
    const [showModalLeave, setShowModalLeave] = useState(false);
    const [showModalSuccess, setShowModalSuccess] = useState(false);
    const [showModalFailed, setShowModalFailed] = useState(false);

    const [responPostJawaban, setResponPostJawaban] = useState(null);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [dataPaketSoal, setDataPaketSoal] = useState([]);
    const [levelSoal, setLevelSoal] = useState("");
    const [listJawabanSiswa, setListJawabanSiswa] = useState({});
    const [radioValue, setRadioValue] = useState([])

    const data = JSON.parse(localStorage.getItem('data'))
    const dataSoal = JSON.parse(localStorage.getItem('soal_sedang_dikerjakan'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getDataPaketSoal = async (paket_soal_id) => {
        await APIServices.getSoal(paket_soal_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    let level = (response.data?.tingkat_kesulitan === "mudah") ? "Easy" : (response.data?.tingkat_kesulitan === "sulit") ? "Hard" : "Medium";
                    setLevelSoal(level);
                    setDataPaketSoal(response.data);
                    console.log(response.data)
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const handleListJawaban = (data, idSoal, noSoal, index) => {
        let _listJawabanSiswa = listJawabanSiswa;
        let _radioValue = radioValue;

        let value = data.target.value;
        _radioValue[index] = value;

        let soal = dataPaketSoal?.list_soal.find(item => item.id === idSoal);
        _listJawabanSiswa[idSoal] = { jawaban: soal.jawaban[value], index: value, nomorSoal: noSoal, listJawaban: soal.jawaban }

        localStorage.setItem('jawaban', JSON.stringify(_listJawabanSiswa));
        setRadioValue(_radioValue)
        setListJawabanSiswa(_listJawabanSiswa)
    }

    const handleSubmitJawaban = () => {
        setLoading(true);
        let arrJawaban = []

        dataPaketSoal?.list_soal.forEach(item => {
            let key = item.id;
            arrJawaban.push({
                id: key,
                jawaban: listJawabanSiswa[key].jawaban,
                list_jawaban: listJawabanSiswa[key].listJawaban
            })
        })


        let body = {
            "paket_soal_id": dataPaketSoal?.paket_soal_id,
            "user_id": data?.user_id,
            "list_soal": arrJawaban
        }
        
        APIServices.postJawaban(body)
            .then(res => {
                localStorage.removeItem("jawaban");

                let response = res.data;
                setResponPostJawaban(response);
                if (response.data?.status !== "fail") {
                    setShowModalSuccess(true)
                } else {
                    setShowModalFailed(true)
                }
                
                body.status = response.data?.status;
                localStorage.setItem('jawabanSiswa', JSON.stringify(body));
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        let loc_split = location.pathname.split('/')
        let id = loc_split[2]
        let adl_id = loc_split[4]
        setSubjectID(id);
        getDetailMapel(id);
        getDataPaketSoal(adl_id);

        // let jawaban = JSON.parse(localStorage.getItem('jawaban')) || {}
        // setListJawabanSiswa(jawaban)
    }, [location])

    return (
        <div style={{ background: "#FFF" }}>
            <ModalLeaveTask visible={showModalLeave} setShowModal={setShowModalLeave} subjectID={subjectID} />
            <ModalTaskEmpty visible={showModal} setShowModal={setShowModal} />
            <ModalTaskSuccess visible={showModalSuccess} setShowModal={setShowModalSuccess} dataSubject={dataSubject} dataPaketSoal={dataPaketSoal} response={responPostJawaban} />
            <ModalTaskFailed visible={showModalFailed} setShowModal={setShowModalFailed} dataSubject={dataSubject} dataPaketSoal={dataPaketSoal} response={responPostJawaban}  />
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "space-between" }}>
                        <div>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/adaptive-learning`) }} />
                            <span>Back to Adaptive Learning List</span>
                        </div>

                        <div className={`badge ${levelSoal.toLowerCase()}`}>
                            <span>
                                • {levelSoal}
                            </span>
                        </div>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataSoal?.judul : "Adaptive Learning"}
                        </span>
                    </Row>
                </>
                :
                <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", paddingLeft: 20 }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/adaptive-learning`) }} />

                        <div>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataSoal?.judul : "Adaptive Learning"}
                        </div>
                    </div>

                    <div className={`badge ${levelSoal.toLowerCase()}`} style={{ marginRight: 20 }}>
                        <span>
                            • {levelSoal}
                        </span>
                    </div>
                </div>
            }

            {loading ?
                <Loading isChildren={true} />
                :
                <>
                    <Formik
                        initialValues={{ nis: null, password: null }}
                        validate={values => {
                            const errors = {};

                            dataPaketSoal.list_soal && dataPaketSoal.list_soal.forEach((item, index) => {
                                if (values["jawaban" + index] === undefined) {
                                    errors["jawaban" + index] = "Oops! Don't let this answer empty.";
                                }
                            })


                            return errors;
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                handleSubmitJawaban()
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue, errors, handleSubmit }) => (
                            <Form>
                                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128, marginBottom: 100 }}>
                                    <div style={{ minWidth: "100%" }}>
                                        {dataPaketSoal.list_soal && dataPaketSoal.list_soal.map((item, index) => (
                                            <div className="card" style={{ padding: 20, borderBottom: "solid 1px #EDEDED", marginBottom: 20 }}>
                                                <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                                                    {item.image ?
                                                        <MathJaxContext>
                                                            <div style={{ display: "flex", justifyContent: "center", width: "100%" }} >
                                                                <Image src={item.image} alt={"soal " + index + 1} style={{ maxWidth: dimension.width - 140, maxHeight: 400 }} />
                                                            </div>
                                                            <div>
                                                                {index + 1}. &nbsp; <Markup content={item.soal} />
                                                            </div>
                                                        </MathJaxContext>
                                                        :
                                                        <MathJaxContext>
                                                            {index + 1}. &nbsp; <Markup content={item.soal} />
                                                        </MathJaxContext>
                                                    }
                                                </div>

                                                <Radio.Group
                                                    style={{ width: "100%" }}
                                                    name={"jawaban" + index}
                                                    value={values["jawaban" + index]}
                                                    onChange={(e) => {
                                                        handleListJawaban(e, item.id, index + 1, index)
                                                        setFieldValue(`jawaban${index}`, e.target.value)
                                                    }}
                                                    status={errors["jawaban" + index] && "error" && SBClicked}
                                                >
                                                    <Row justify="start">
                                                        {item.jawaban.map((jawaban, index) => (
                                                            <Col span={!jawaban.includes(".jpg") && 24}>
                                                                <Radio
                                                                    value={index}
                                                                    style={{ alignItems: "center", verticalAlign: "middle", marginRight: 30 }}
                                                                >
                                                                    {jawaban.includes(".jpg") || jawaban.includes(".png") || jawaban.includes(".jpeg") ?
                                                                        <Image src={jawaban} alt={"Soal " + index} height={200} />
                                                                        :
                                                                        <MathJaxContext>
                                                                            <div className="elipsis">
                                                                                {jawaban}
                                                                            </div>
                                                                        </MathJaxContext>
                                                                    }
                                                                </Radio>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Radio.Group>
                                                <div style={{ color: 'red', marginTop: 5 }}>{(SBClicked && errors["jawaban" + index]) || ""}</div>
                                            </div>
                                        ))}
                                    </div>
                                </Row>

                                <div className="card"
                                    style={{
                                        position: "fixed", display: "flex", width: widthValue,
                                        padding: 20, borderBottom: "solid 1px #EDEDED", margin: `0px ${marginValue}px`, bottom: 0
                                    }}
                                >
                                    <button className="button-secondary" style={{ marginRight: 10 }}
                                        onClick={() => {
                                            setShowModalLeave(true)
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button className="button-primary" style={{ marginLeft: 10 }}
                                        onClick={(e) => {
                                            if (Object.keys(listJawabanSiswa).length < dataPaketSoal?.list_soal?.length) {
                                                setShowModal(true)
                                            }
                                            setSBClicked(true);
                                            handleSubmit(e)
                                        }}
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </>
            }
        </div>
    );
}

export default (AdaptiveLearningDetail)