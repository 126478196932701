import React, { useEffect, useState } from "react";
import { Row, Input, Collapse, Progress } from 'antd';
import { ArrowLeftOutlined, SearchOutlined } from '@ant-design/icons';
import { Formik, Form } from 'formik';
import { APIServices } from "../services";
// import { useNavigate } from "react-router-dom";
import Loading from "../components/LoadingScreen";
import useWindowDimensions from "../utils/size-window";
import { useNavigate } from "react-router-dom";

const { Panel } = Collapse;

const ReportDetail = (props) => {
    const navigate = useNavigate();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    const [isRender, setIsRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [initialListMapel, setInitialListMapel] = useState([]);
    const [listMapel, setListMapel] = useState([]);
    const [mapelSelected, setMapelSelected] = useState(1);

    const [listScore, setListScore] = useState([])

    const getListMapel = async (tingkat_id) => {
        setIsLoading(true);
        await APIServices.getMapel(tingkat_id)
            .then(res => {
                let response = res.data;

                let mapelSelected = JSON.parse(localStorage.getItem('mapel_selected'))
                if (mapelSelected) {
                    setMapelSelected(mapelSelected);
                    getListScoreMapel(mapelSelected, props.data?.user_id)
                } else {
                    setMapelSelected(response.data[0]?.id)
                    getListScoreMapel(response.data[0]?.id, props.data?.user_id)
                }

                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setInitialListMapel(response.data);
                    setListMapel(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getListScoreMapel = async (mapel_id, user_id) => {
        await APIServices.getERaport(mapel_id, user_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setListScore(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        if (isRender) {
            let defaultTingkat = { "SD": 1, "SMP": 7, "TK": 10 };
            getListMapel(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang]);

            setIsRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        console.log(listMapel)
        if (listMapel.length > 0) {
            setMapelSelected(listMapel[0]?.id)
            getListScoreMapel(listMapel[0]?.id, props.data?.user_id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, listMapel)

    const handleSelectMapel = (item) => {
        setMapelSelected(item.id);
        getListScoreMapel(item.id, props.data?.user_id)
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div style={{background: "#FFF"}}>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/e-report`) }} />
                <span>Back to E-Report</span>
            </Row>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, marginTop: 20 }}>
                <div style={{ minWidth: "100%" }}>
                    <Formik
                        initialValues={{ subject: "" }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                console.log(values)
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Row style={{ marginBottom: 10 }}>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Search Subject"
                                        suffix={<SearchOutlined />}
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="subject"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setFieldValue("subject", e.target.value)

                                            let search = e.target.value?.toLowerCase();
                                            let arr = initialListMapel.filter(item => item.name.toLowerCase().indexOf(search) > -1);
                                            if (e.target.value) {
                                                setListMapel(arr)
                                            } else {
                                                setListMapel(initialListMapel)
                                            }
                                        }}
                                    />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Row>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, }}>
                <div style={{ display: "flex", width: "100%", overflowY: "auto" }}>
                    {listMapel && listMapel.map(item => (
                        <div className={`card-kelas ${mapelSelected === item.id && "active"}`} style={{ width: `${100 / listMapel.length}%`, minWidth: 120 }}
                            onClick={() => { handleSelectMapel(item) }} >
                            {item.name}
                        </div>
                    ))}
                </div>
            </Row>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, marginTop: 20 }}>
                <div style={{ minWidth: "100%" }}>
                    {listScore && listScore.length > 0 ?
                        <Collapse accordion expandIconPosition="right" style={{ background: "#FFF" }}>
                            {listScore.map((item, index) => (
                                <Panel key={index.toString()}
                                    header={
                                        <div className="learning-type">
                                            {item.icon ?
                                                <img src={`${process.env.REACT_APP_DIRECTORY}/${item.icon}`} alt="Subject" style={{ width: 60, height: 60 }} />
                                                :
                                                <div style={{ background: "#616161", width: 60, height: 60 }} />
                                            }
                                            <div style={{ marginLeft: 40 }}>
                                                {item.nama}
                                            </div>
                                        </div>
                                    }
                                >
                                    <Collapse accordion expandIconPosition="right" style={{ background: "#FFF" }} >
                                        {item.subbab && item.subbab.map((item_subbab, index) => (
                                            <Panel key={index.toString()}
                                                header={
                                                    <div className="learning-type">
                                                        <div style={{ marginLeft: 40 }}>
                                                            {item_subbab.nama}
                                                        </div>
                                                    </div>
                                                }
                                            >
                                                <div style={{ minWidth: "100%" }}>
                                                    <div className="card" style={{ padding: 20 }}>
                                                        <div style={{ margin: "auto" }}>
                                                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                                                <span>Easy</span>
                                                                <span style={{ color: "#0ABC5D" }}>
                                                                    {item_subbab.nilai[0]?.total_benar}/{item_subbab.nilai[0]?.total_terjawab}
                                                                    <span style={{ color: "#616161", fontSize: 14 }}>&nbsp; Question</span>
                                                                </span>
                                                            </div>
                                                            <Progress percent={item_subbab.nilai[0]?.percentage} showInfo={false} strokeColor="#0ABC5D" trailColor="#81ffbc" />
                                                        </div>
                                                        <div style={{ margin: "auto" }}>
                                                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                                                <span>Medium</span>
                                                                <span style={{ color: "#FFAB05" }}>
                                                                    {item_subbab.nilai[1]?.total_benar}/{item_subbab.nilai[1]?.total_terjawab}
                                                                    <span style={{ color: "#616161", fontSize: 14 }}>&nbsp; Question</span>
                                                                </span>
                                                            </div>
                                                            <Progress percent={item_subbab.nilai[1]?.percentage} showInfo={false} strokeColor="#FFAB05" trailColor="#ffde9d" />
                                                        </div>
                                                        <div style={{ margin: "auto" }}>
                                                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                                                <span>Hard</span>
                                                                <span style={{ color: "#C52421" }}>
                                                                    {item_subbab.nilai[2]?.total_benar}/{item_subbab.nilai[2]?.total_terjawab}
                                                                    <span style={{ color: "#616161", fontSize: 14 }}>&nbsp; Question</span>
                                                                </span>
                                                            </div>
                                                            <Progress percent={item_subbab.nilai[2]?.percentage} showInfo={false} strokeColor="#C52421" trailColor="#ffb1b0" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        ))}
                                    </Collapse>
                                </Panel>
                            ))}
                        </Collapse>
                        :
                        <div className="card" style={{ display: "block", textAlign: "center", padding: 20 }}>
                            <img src={"/image/Empty.png"} alt="More"
                                style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 250, height: 200 }}
                            />
                            <div style={{ fontWeight: "bold", fontSize: 25 }}>
                                No Score for this Subject
                            </div>
                            <div>
                                You've no score for this subject <br />
                                Please choose another subject
                            </div>
                        </div>
                    }
                </div>
            </Row>
        </div>
    );
}

export default (ReportDetail)