import React from "react";
import { Layout, Row, Spin } from 'antd';

const Loading = (props) => {

    return (
        <>
            <Row style={{ marginLeft: 60, marginRight: 60, height: "100vh" }} align="middle" justify="center">
                <Spin />
            </Row>
        </>
    );
}

export default (Loading)