import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Image, Collapse } from 'antd';
import { ArrowLeftOutlined, CaretUpOutlined } from '@ant-design/icons';
import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";
import { ModalLeaveTask, ModalTaskEmpty } from "../components/Modal";
import { Markup } from "interweave";
import { MathJaxContext } from "better-react-mathjax";
import YouTube from "react-youtube";

const { Panel } = Collapse;

const AdaptiveLearningStudy = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    let widthValue = (dimension.width > 800) ? dimension.width - 175 : dimension.width - 95

    const [loading, setLoading] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [showModalLeave, setShowModalLeave] = useState(false);

    const [status, setStatus] = useState();

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [dataPaketSoal, setDataPaketSoal] = useState([]);
    const [levelSoal, setLevelSoal] = useState("");

    const dataSoal = JSON.parse(localStorage.getItem('soal_sedang_dikerjakan'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    // const getDataPaketSoal = async (paket_soal_id) => {
    //     await APIServices.getSoal(paket_soal_id)
    //         .then(res => {
    //             let response = res.data;
    //             if (response) {
    //                 let level = (response.data?.tingkat_kesulitan === "mudah") ? "Easy" : (response.data?.tingkat_kesulitan === "sulit") ? "Hard" : "Medium";
    //                 setLevelSoal(level);
    //                 setDataPaketSoal(response.data);
    //                 console.log(response.data)
    //             }
    //             setLoading(false);
    //         })
    //         .catch(err => {
    //             console.log(err);
    //         })
    // }

    const getDataPembahasanSoal = async (paket_soal_id) => {
        let jawabanSiswa = JSON.parse(localStorage.getItem('jawabanSiswa'))
        setStatus(jawabanSiswa.status);

        await APIServices.getPembahasan(jawabanSiswa)
            .then(res => {
                let response = res.data;
                if (response) {
                    let level = (response.data?.tingkat_kesulitan === "mudah") ? "Easy" : (response.data?.tingkat_kesulitan === "sulit") ? "Hard" : "Medium";
                    setLevelSoal(level);
                    setDataPaketSoal(response.data);
                    console.log(response.data)
                }
                setLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        let loc_split = location.pathname.split('/')
        let id = loc_split[2]
        let adl_id = loc_split[4]
        setSubjectID(id);
        getDetailMapel(id);
        // getDataPaketSoal(adl_id);
        getDataPembahasanSoal(adl_id);
    }, [location])

    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts = {
        width: dimension.width - (3 * marginValue) - 30,
        height: 500,
        playerVars: {
            // autoplay: 1,
            rel: 0,
            listType: "playlist",
            modestbranding: 1
        },
    };

    return (
        
        <div style={{ background: "#FFF" }}>
            <ModalLeaveTask visible={showModalLeave} setShowModal={setShowModalLeave} subjectID={subjectID} study={true} />
            <ModalTaskEmpty visible={showModal} setShowModal={setShowModal} />
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "space-between" }}>
                        <div>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/adaptive-learning`) }} />
                            <span>Back to Adaptive Learning List</span>
                        </div>

                        <div className={`badge ${levelSoal.toLowerCase()}`}>
                            <span>
                                • {levelSoal}
                            </span>
                        </div>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataSoal?.judul + " (Pembahasan)" : "Pembahasan"}
                        </span>
                    </Row>
                </>
                :
                <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                    <div style={{ display: "flex", paddingLeft: 20 }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/adaptive-learning`) }} />

                        <div>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataSoal?.judul + " (Pembahasan)" : "Pembahasan"}
                        </div>
                    </div>

                    <div className={`badge ${levelSoal.toLowerCase()}`} style={{ marginRight: 20 }}>
                        <span>
                            • {levelSoal}
                        </span>
                    </div>
                </div>
            }

            {loading ?
                <Loading isChildren={true} />
                :
                <>

                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128, marginBottom: 100 }}>
                        <div style={{ minWidth: "100%" }}>
                            {dataPaketSoal.list_soal && dataPaketSoal.list_soal.map((item, index) => (
                                <div className="card" style={{ padding: 20, borderBottom: "solid 1px #EDEDED", marginBottom: 20 }}>
                                    <div style={{ fontWeight: "bold", marginBottom: 10 }}>
                                        {item.image ?
                                            <MathJaxContext>
                                                <div style={{ display: "flex", justifyContent: "center", width: "100%" }} >
                                                    <Image src={item.image} alt={"soal " + index + 1} style={{ maxWidth: dimension.width - 140, maxHeight: 400 }} />
                                                </div>
                                                <div>
                                                    {index + 1}. &nbsp; <Markup content={item.soal} />
                                                </div>
                                            </MathJaxContext>
                                            :
                                            <MathJaxContext>
                                                {index + 1}. &nbsp; <Markup content={item.soal} />
                                            </MathJaxContext>
                                        }
                                    </div>

                                    <Row justify="start">
                                        {item.jawaban.map((jawaban, index) => (
                                            <Col span={!jawaban.includes(".jpg") && 24}>
                                                {jawaban.includes(".jpg") || jawaban.includes(".png") || jawaban.includes(".jpeg") ?
                                                    <>
                                                        {((jawaban === item.jawaban_siswa) && !item.is_correct) ?
                                                            <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4, background: "#FFEED9", borderRadius: 5, color: "#FFAB05" }}>
                                                                <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #FFAB05", display: "inline-flex", marginRight: 5, justifyContent: "center", alignItems: "center" }}>
                                                                    <span style={{ width: 8, height: 8, borderRadius: "50%", background: "#FFAB05" }}></span>
                                                                </span>
                                                                <Image src={jawaban} alt={"Soal " + index} height={200} />
                                                            </div>
                                                            :
                                                            (jawaban === item.jawaban_benar) ?
                                                                <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4, background: "#CFFFE5", borderRadius: 5, color: "#0ABC5D" }}>
                                                                    <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #0ABC5D", display: "inline-flex", marginRight: 5, justifyContent: "center", alignItems: "center" }}>
                                                                        <span style={{ width: 8, height: 8, borderRadius: "50%", background: "#0ABC5D" }}></span>
                                                                    </span>
                                                                    <Image src={jawaban} alt={"Soal " + index} height={200} />
                                                                </div>
                                                                :
                                                                <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4 }}>
                                                                    <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #C2C2C2", display: "inline-flex", marginRight: 5 }}></span>
                                                                    <Image src={jawaban} alt={"Soal " + index} height={200} />
                                                                </div>
                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        {((jawaban === item.jawaban_siswa) && !item.is_correct) ?
                                                            <MathJaxContext>
                                                                <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4, background: "#FFEED9", borderRadius: 5, color: "#FFAB05" }}>
                                                                    <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #FFAB05", display: "inline-flex", marginRight: 5, justifyContent: "center", alignItems: "center" }}>
                                                                        <span style={{ width: 8, height: 8, borderRadius: "50%", background: "#FFAB05" }}></span>
                                                                    </span>
                                                                    {jawaban}
                                                                </div>
                                                            </MathJaxContext>
                                                            :
                                                            (jawaban === item.jawaban_benar) ?
                                                                <MathJaxContext>
                                                                    <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4, background: "#CFFFE5", borderRadius: 5, color: "#0ABC5D" }}>
                                                                        <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #0ABC5D", display: "inline-flex", marginRight: 5, justifyContent: "center", alignItems: "center" }}>
                                                                            <span style={{ width: 8, height: 8, borderRadius: "50%", background: "#0ABC5D" }}></span>
                                                                        </span>
                                                                        {jawaban}
                                                                    </div>
                                                                </MathJaxContext>
                                                                :
                                                                <MathJaxContext>
                                                                    <div className="elipsis" style={{ alignItems: "center", display: "flex", padding: 4 }}>
                                                                        <span style={{ width: 16, height: 16, borderRadius: "50%", background: "#FFF", border: "solid 2px #C2C2C2", display: "inline-flex", marginRight: 5 }}></span>
                                                                        {jawaban}
                                                                    </div>
                                                                </MathJaxContext>
                                                        }
                                                    </>
                                                }
                                            </Col>
                                        ))}
                                    </Row>

                                    {item.pembahasan_text &&
                                        <Row justify="start" style={{ marginTop: 10 }}>
                                            <Col span={24}>
                                                <Collapse defaultActiveKey={['1']} expandIconPosition="end" expandIcon={({ isActive }) => <CaretUpOutlined rotate={isActive ? 0 : 180} />}>
                                                    <Panel header={<span><b>See the study</b></span>} key="0">
                                                        <div><MathJaxContext><Markup content={item.pembahasan_text} /></MathJaxContext></div>
                                                    </Panel>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    }

                                    {item.pembahasan_video &&
                                        <Row justify="center" style={{ marginTop: 10 }}>
                                            <Col span={24}>
                                                <Collapse defaultActiveKey={['1']} expandIconPosition="end">
                                                    <Panel header={<span><b>See the study</b></span>} key="0">
                                                        <YouTube videoId={item.pembahasan_video} opts={opts} onReady={onPlayerReady} />
                                                    </Panel>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                    }
                                </div>
                            ))}
                        </div>
                    </Row>

                    <div className="card"
                        style={{
                            position: "fixed", display: "flex", width: widthValue,
                            padding: 20, borderBottom: "solid 1px #EDEDED", margin: `0px ${marginValue}px`, bottom: 0
                        }}
                    >
                        <button className="button-secondary" style={{ marginRight: 10 }}
                            onClick={() => {
                                setShowModalLeave(true)
                            }}
                        >
                            Cancel
                        </button>
                        <button className="button-primary" style={{ marginLeft: 10 }}
                            onClick={(e) => {
                                let loc_split = location.pathname.split('/')
                                let adl_id = loc_split[4]
                                navigate(`/subject/${subjectID}/adaptive-learning/${adl_id}`)
                            }}
                        >
                            {status === "pass" ? "Start Next Level" : "Let's Try Again"}
                        </button>
                    </div>
                </>
            }
        </div >
    );
}

export default (AdaptiveLearningStudy)