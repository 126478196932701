import React, { useState } from "react";
import { Row, Input, Button } from 'antd';
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { Formik, Form } from 'formik';
// import Auth from "../services/Auth";
import { ModalServerError, ModalSuccessChangePass } from "../components/Modal";
import useWindowDimensions from "../utils/size-window";
import { useNavigate } from "react-router-dom";
import { APIServices } from "../services";

const ChangePassword = (props) => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    const [loading, setLoading] = useState(false)
    const handleChangePass = (body) => {
        setLoading(true)
        APIServices.editPassword(body)
            .then(res => {
                console.log(res)
                setLoading(false)
                setShowModal(true)
            })
            .catch(err => {
                if(err?.request?.status === 500){
                    setShowModal2(true);
                } else {
                    setShowModal2(true);
                }
                console.log(err)
                
                setLoading(false)
            })
    }

    return (
        <div style={{ background: "#FFF" }}>
            <ModalSuccessChangePass visible={showModal} setShowModal={setShowModal} />
            <ModalServerError visible={showModal2} setShowModal={setShowModal2}/>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/profile`) }} />
                <span>Back to Profile</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <span style={{ fontWeight: "bold", fontSize: 20 }}>Change Password</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: 600 }}>
                    <Formik
                        initialValues={{
                            old_password: ""
                        }}

                        validate={values => {
                            const errors = {};
                            if (!values.old_password) {
                                errors.old_password = 'Previous Password is required!';
                            } else {
                                if (values.old_password.length < 8) {
                                    errors.old_password = 'Previous Password must be at least 8 characters.!';
                                }
                            }

                            if (!values.new_password) {
                                errors.new_password = 'New Password is required!';
                            } else {
                                if (values.new_password.length < 8) {
                                    errors.new_password = 'New Password must be at least 8 characters.!';
                                }
                            }

                            if (!values.new_password_confirmation) {
                                errors.new_password_confirmation = 'New Password Confirmation is required!';
                            } else {
                                if (values.new_password !== values.new_password_confirmation) {
                                    errors.new_password_confirmation = 'New Password Confirmation not match!';
                                }
                            }

                            return errors;
                        }}

                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                console.log(values)
                                handleChangePass(values)
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue, errors, handleSubmit }) => (
                            <Form>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Previous Password</span>
                                    <Input.Password
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Password"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="old_password"
                                        value={values.old_password}
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("old_password", e.target.value) }}
                                    />
                                    <span style={{ color: 'red' }}>{errors.old_password || ""}</span>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>New Password</span>
                                    <Input.Password
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Password"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="new_password"
                                        value={values.new_password}
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("new_password", e.target.value) }}
                                    />
                                    <span style={{ color: 'red' }}>{errors.new_password || ""}</span>
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Confirm New Password</span>
                                    <Input.Password
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Password"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="new_password_confirmation"
                                        value={values.new_password_confirmation}
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("new_password_confirmation", e.target.value) }}
                                    />
                                    <span style={{ color: 'red' }}>{errors.new_password_confirmation || ""}</span>
                                </Row>
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                    <Button className="button-primary" type="submit" disabled={loading} loading={loading} onClick={handleSubmit}>
                                        Save Changes
                                    </Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Row>
        </div>
    );
}

export default (ChangePassword)