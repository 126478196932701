import React, { useState } from "react";
import { Row } from 'antd';
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import Auth from "../services/Auth";
import { ModalLogout } from "../components/Modal";
import useWindowDimensions from "../utils/size-window";
import { useNavigate } from "react-router-dom";

const PrivacyPolicy = (props) => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    return (
        <div style={{background:"#FFF"}}>
            <ModalLogout visible={showModal} setShowModal={setShowModal} logout={Auth.logout} />
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/profile`) }} />
                <span>Back to Profile</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <span style={{fontWeight: "bold", fontSize: 20}}>Privacy & Policy</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: 600, display: "block" }}>
                    <div style={{ display: "flex", marginBottom: 20 }}>
                        <div
                            style={{
                                width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20,
                                borderRadius: "50%", border: "solid 4px #FFF",
                                backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/camera.png`} alt="Camera"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED" }}>
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#000" }}>
                                        Camera
                                    </div>
                                    <div style={{color:"#757575"}}>• Take pictures and videos</div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div div style={{ display: "flex", marginBottom: 20 }}>
                        <div 
                            style={{ 
                                width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20,
                                borderRadius: "50%", border: "solid 4px #FFF",
                                backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/location.png`} alt="Location"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED" }}>
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#000" }}>
                                        Location
                                    </div>
                                    <div style={{color:"#757575"}}>
                                        • Access precise location (GPS and Network-based) <br/>
                                        • Access approximate location (Network-based)
                                    </div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div div style={{ display: "flex", marginBottom: 20 }}>
                        <div 
                            style={{
                                 width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20, 
                                 borderRadius: "50%", border: "solid 4px #FFF",
                                 backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/storage.png`} alt="Storage"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED" }}>
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#000" }}>
                                        Storage
                                    </div>
                                    <div style={{color:"#757575"}}>
                                        • Read content of your SD Card <br/>
                                        • Modify or Delete SD Card Content
                                    </div>
                                </div>
                                <div style={{ marginTop: 20, fontSize: 20, alignItems: "center" }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div div style={{ display: "flex", marginBottom: 20 }}>
                        <div 
                            style={{
                                 width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20, 
                                 borderRadius: "50%", border: "solid 4px #FFF",
                                 backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/others.png`} alt="Others"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED" }}>
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#000" }}>
                                        Others
                                    </div>
                                    <div style={{color:"#757575"}}>
                                        • Advertising ID Permission <br/>
                                        • Have full network access <br/>
                                        • View network connection <br/>
                                        • Prevent phone from sleeping <br/>
                                        • Play install API reference
                                        
                                    </div>
                                </div>
                                <div style={{ marginTop: 20, fontSize: 20, alignItems: "center" }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default (PrivacyPolicy)