import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";

import Content from "./components/Content";
import AdaptiveLearning from "./pages/AdaptiveLearning";
import AdaptiveLearningDetail from "./pages/AdaptiveLearningDetail";
import ForgotPassword from "./pages/ForgotPassword";
import LearningModule from "./pages/LearningModule";
import LearningModuleDetail from "./pages/LearningModuleDetail";
import LearningSimulation from "./pages/LearningSimulation";
import LearningSimulationDetail from "./pages/LearningSimulationDetail";
import LearningVideo from "./pages/LearningVideo";
import LearningVideoDetail from "./pages/LearningVideoDetail";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ResetPassword from "./pages/ResetPassword";
import SubjectDetail from "./pages/SubjectDetail";
import UpdatedSubject from "./pages/UpdatedSubject";
import VerifyEmail from "./pages/VerifyEmail";

import Auth from "./services/Auth";
import AdaptiveLearningStudy from "./pages/AdaptiveLearningStudy";

function App() {

  return (
    <div style={{ backgroundColor: "#E0E0E0" }}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Content />}>
            <Route path="/subject" />
            <Route path="/e-report" />
            <Route path="/e-report/detail" />
            <Route path="/achievement" />
            <Route path="/profile" />
            <Route path="/personal-information" />
            <Route path="/privacy-policy" />
            <Route path="/change-password" />
          </Route>
          <Route path="/subject/:id" element={Auth.isLogin() ? <SubjectDetail /> : <Navigate to="/" replace />} />
          <Route path="/subject/:id/learning-module" element={Auth.isLogin() ? <LearningModule />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/learning-module/:modul_id" element={Auth.isLogin() ? <LearningModuleDetail />: <Navigate to="/" replace />} />
          <Route path="/modul/:modul_id" element={Auth.isLogin() ? <LearningModuleDetail />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/learning-simulation" element={Auth.isLogin() ? <LearningSimulation />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/learning-simulation/:sim_id" element={Auth.isLogin() ? <LearningSimulationDetail />: <Navigate to="/" replace />} />
          <Route path="/simulasi/:sim_id" element={Auth.isLogin() ? <LearningSimulationDetail />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/video" element={Auth.isLogin() ? <LearningVideo />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/video/:video_id" element={Auth.isLogin() ? <LearningVideoDetail />: <Navigate to="/" replace />} />
          <Route path="/video/:video_id" element={Auth.isLogin() ? <LearningVideoDetail />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/adaptive-learning" element={Auth.isLogin() ? <AdaptiveLearning />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/adaptive-learning/:adl_id" element={Auth.isLogin() ? <AdaptiveLearningDetail />: <Navigate to="/" replace />} />
          <Route path="/subject/:id/adaptive-learning/:adl_id/study" element={Auth.isLogin() ? <AdaptiveLearningStudy />: <Navigate to="/" replace />} />
          <Route path="/updated-subject" element={Auth.isLogin() ? <UpdatedSubject />: <Navigate to="/" replace />} />
          <Route path="/register-student" element={<Register loginAs={"Student"} />} />
          <Route path="/register-visitor" element={<Register loginAs={"Visitor"} />} />
          <Route path="/forgot-password-student" element={<ForgotPassword loginAs={"Student"} />} />
          <Route path="/forgot-password-visitor" element={<ForgotPassword loginAs={"Visitor"} />} />
          <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
          <Route path="/verify-email/:email" element={<VerifyEmail />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;