import React, { useEffect, useState } from "react";
import { Modal, Button, message, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { APIServices } from "../services";

const { Dragger } = Upload;
// import { useNavigate } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;

export const ModalUploadPhoto = (props) => {
    let token = JSON.parse(localStorage.getItem('token_siswa'));
    let data = JSON.parse(localStorage.getItem('data'));
    const [response, setResponse] = useState(null)

    const uploadProps = {
        name: 'file',
        multiple: true,
        action: `${baseURL}/upload/photo`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                console.log(info)
                setResponse(info.file.response)
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            setResponse(null)
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    const [loading, setLoading] = useState(false)
    const handleChangePhoto = () => {
        let body = {
            photo: response.data?.image_path,
            email: data.email
        }

        setLoading(true)
        APIServices.editProfile(body)
            .then(res => {
                console.log(res)
                setLoading(false)
                handleGetProfile()
            })
            .catch(err => {
                message.error(`Change photo failed.`);
                console.log(err)

                setLoading(false)
            })
    }

    const handleGetProfile= () => {
        setLoading(true)
        APIServices.getProfile()
            .then(res => {
                console.log(res)
                setLoading(false)
                if(res.data){
                    localStorage.setItem('data', JSON.stringify(res.data?.data));
                }
                props.setShowModal(false)
                window.location.reload()
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <Modal
                centered
                open={props.visible}
                closable={false}
                onOk={() => props.setShowModal(false)}
                footer={[
                    <Button className="button-secondary" key="submit" type="primary" style={{ width: "40%" }} onClick={() => props.setShowModal(false)}>
                        Cancel
                    </Button>,
                    <Button className="button-primary" key="submit" type="primary" style={{ width: "40%" }} loading={loading} disabled={loading} onClick={() => handleChangePhoto()}>
                        Change
                    </Button>
                ]}
            >

                <Dragger {...uploadProps}>
                    {response ?
                        <img src={response.data?.image_url} alt="Profile Icon" width={200} height={200}
                            style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto", borderRadius: "50%", objectFit: "cover", border: "solid 1px #000" }}
                        />
                        :
                        <>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </>
                    }
                </Dragger>
            </Modal>
        </div>
    );
}