/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Layout, Row, Col, Spin } from 'antd';
// import {
//     MailOutlined,
//     LockOutlined
// } from '@ant-design/icons';

import { APIServices } from "../services";

const { Content } = Layout;

const VerifyEmail = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const [loading, setLoading] = useState(true)

    const handleVerify = (body) => {
        setLoading(true)
        APIServices.verifyEmail(body)
            .then(res => {
                setLoading(false)
                setTimeout(() => {
                    navigate("/login")
                }, 2000)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }

    useEffect(() => {
        let loc_split = location.pathname.split('/')
        let email = loc_split[2]
        handleVerify(email)
    }, [location])

    return (
        <Layout className="layout">
            <Row justify="center" align="middle">
                <Col style={{ width: "100%", height: "100vh", background: "#FFF" }}>
                    <Content>
                        <Row justify="center" align="middle" style={{ marginBottom: 5, marginTop: 20, height: "100vh", }}>
                            {loading ?
                                <Spin />
                                :
                                <div style={{display:"block", textAlign:"center"}}>
                                    <img src={"/image/Success.png"} alt="More"
                                        style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                    />
                                    <div style={{fontWeight:"bold", fontSize:25}}>
                                        Yeay! Account is Verified
                                    </div>
                                    <div>
                                        Congratulation your account already verified
                                    </div>
                                </div>
                            }
                        </Row>
                    </Content>
                </Col>
            </Row>
        </Layout>
    );
}

export default (VerifyEmail)