/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
// import PSPDFKit from "pspdfkit";

import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";
// import PdfViewerComponent from "../utils/PdfViewerComponent";
// import PDFReader from "../utils/PdfReader"
// import ViewSDKClient from "../utils/ViewSDKClient";

const LearningModuleDetail = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [isLoading, setIsLoading] = useState(true);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [dataModul, setDataModul] = useState([]);

    const data = JSON.parse(localStorage.getItem('data'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getDataModul = async (paket_soal_id) => {
        await APIServices.getModulDetail(paket_soal_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataModul(response.data);
                    if (!subjectID) {
                        setSubjectID(response.data?.mata_pelajaran?.id);
                        getDetailMapel(response.data?.mata_pelajaran?.id);
                    }
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        let id = params.id;
        let modul_id = params.modul_id;

        if(id){
            setSubjectID(id);
            getDetailMapel(id);
        }
        getDataModul(modul_id);
    }, [location])

    // const loadPDF = () => {
    //     const viewSDKClient = new ViewSDKClient();
    //     viewSDKClient.ready().then(() => {
    //         viewSDKClient.previewFile(
    //             "pdf-div",
    //             {
    //                 defaultViewMode: "FIT_WIDTH",
    //                 showAnnotationTools: true,
    //                 showLeftHandPanel: true,
    //                 showPageControls: true,
    //                 showDownloadPDF: true,
    //                 showPrintPDF: true,
    //             },
    //             dataModul?.pdf_url
    //         );
    //     });
    // };

    return (
        <div style={{ background: "#FFF" }}>
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/learning-module`) }} />
                        <span>Back to Learning Module List</span>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataModul?.name : "Learning Module"}
                        </span>
                    </Row>
                </>
                :
                <Row>
                    <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", paddingLeft: 20 }}>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}`) }} />

                            <div>
                                {dataSubject?.name ? dataSubject?.name + " - " + dataModul?.name : "Learning Module"}
                            </div>
                        </div>

                        <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>
                    </div>
                </Row>
            }

            {isLoading ?
                <Loading isChildren={true} />
                :
                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128 }}>
                    <div className="PDF-viewer">
                        <iframe src={dataModul?.pdf_url} title={dataModul?.name} width={dimension.width - (2 * marginValue)} height={dimension.height - 250} alt="" allowfullscreen>
                        </iframe>
                    </div>
                </Row>
            }
        </div>
    );
}

export default (LearningModuleDetail)