import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import YouTube from 'react-youtube';

import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";

const LearningVideoDetail = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [isLoading, setIsLoading] = useState(true);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [dataVideo, setDataVideo] = useState([]);

    const data = JSON.parse(localStorage.getItem('data'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getDataVideo = async (video_id) => {
        await APIServices.getVideoDetail(video_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataVideo(response.data);
                    if (!subjectID) {
                        setSubjectID(response.data?.mata_pelajaran.id);
                        getDetailMapel(response.data?.mata_pelajaran.id);
                    }
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        // let loc_split = location.pathname.split('/')
        let id = params.id
        let video_id = params.video_id

        if (id) {
            setSubjectID(id);
            getDetailMapel(id);
        }

        getDataVideo(video_id);
    }, [location])


    const onPlayerReady = (event) => {
        // access to player in all event handlers via event.target
        event.target.pauseVideo();
    }

    const opts = {
        width: dimension.width - (2 * marginValue),
        height: 500,
        playerVars: {
            // autoplay: 1,
            rel: 0,
            listType: "playlist",
            modestbranding: 1
        },
    };

    return (
        <div style={{ background: "#FFF" }}>
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "start" }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/video`) }} />
                        <span>Back to Learning Video List</span>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataVideo?.name : "Learning Video"}
                        </span>
                    </Row>
                </>
                :
                <Row>
                    <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", paddingLeft: 20 }}>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }}
                                onClick={() => {
                                    if (subjectID) {
                                        navigate(`/subject/${subjectID}`)
                                    } else {
                                        navigate(`/subject/${dataVideo?.mata_pelajaran?.id}`)
                                    }
                                }}
                            />

                            <div>
                                {dataSubject?.name ? dataSubject?.name + " - " + dataVideo?.name : "Learning Video"}
                            </div>
                        </div>

                        <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>
                    </div>
                </Row>
            }

            {isLoading ?
                <Loading isChildren={true} />
                :
                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128 }}>
                    <div style={{ minWidth: "100%" }}>

                        <YouTube videoId={dataVideo?.youtubeId} opts={opts} onReady={onPlayerReady} />

                    </div>
                </Row>
            }
        </div>
    );
}

export default (LearningVideoDetail)