import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from 'formik';
import { Layout, Row, Col, Input, Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import TopBar from "../components/TopBar";
import { APIServices } from "../services";
import useWindowDimensions from "../utils/size-window";
import { ModalFailedForgotPass } from "../components/Modal";

const { Content } = Layout;

const ForgotPassword = (props) => {
    let navigate = useNavigate();
    const dimension = useWindowDimensions();
    const loginAs = props.loginAs;

    const [loading, setLoading] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    const [requested, setRequested] = useState(false);

    const handleForgotPassword = (body) => {
        body.source_api_call = "web";
        setLoading(true)
        if (loginAs === "Student") {
            APIServices.forgotPasswordStudent(body)
                .then(res => {
                    let response = res.data;
                    if (response) {
                        setRequested(true)
                        setTimeout(() => {

                            navigate("/login");
                        }, 2000)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setShowModal(true)
                    setErrorMessage(err.response?.data?.message)
                    setLoading(false)
                })
        } else {
            APIServices.forgotPassword(body)
                .then(res => {
                    let response = res.data;
                    if (response) {
                        setRequested(true)
                        setTimeout(() => {

                            navigate("/login");
                        }, 2000)
                    }
                    setLoading(false)
                })
                .catch(err => {
                    console.log(err)
                    setShowModal(true)
                    setErrorMessage(err.response?.data?.message)
                    setLoading(false)
                })
        }
    }

    return (
        <Layout className="layout">
            <ModalFailedForgotPass visible={showModal} setShowModal={setShowModal} message={errorMessage}/>
            {
                requested ?
                    <Row justify="center" align="middle">
                        <Col style={{ width: "100%", minHeight: "100vh", background: "#FFF", marginTop: 50 }}>
                            <Content>
                                <div style={{ display: "block", textAlign: "center" }}>
                                    <img src={"/image/Success.png"} alt="More"
                                        style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                    />
                                    <div style={{ fontWeight: "bold", fontSize: 25 }}>
                                        Forgot Password Request Sent!
                                    </div>
                                    <div>
                                        {loginAs === "Student" ? "Takes time to admin reset your password" : "We sent a link to your email to get a recovery of your account!"}
                                    </div>
                                </div>
                            </Content>
                        </Col>
                    </Row>

                    :

                    <Row justify="center" align="middle">
                        <Col span={dimension.width > 800 ? 12 : 24} style={{ width: "100%", height: "100vh", background: "#FFF" }}>
                            <TopBar />
                            <Content>
                                <Row style={{ marginLeft: 60, marginRight: 60, marginTop: 20, justifyContent: "start" }}>
                                    <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/login`) }} />
                                    <span>Back to Login</span>
                                </Row>
                                <Row align="middle" style={{ marginLeft: 60, marginRight: 60, marginBottom: 5, marginTop: 120 }}>
                                    <span style={{ textAlign: "left", color: "#024102", fontSize: "2rem", fontWeight: "bold" }}>
                                        Forgot Password
                                    </span>
                                </Row>
                                <Row align="middle" style={{ marginLeft: 60, marginRight: 60, marginBottom: 40 }}>
                                    <span style={{ textAlign: "left", color: "#000", fontSize: "1rem" }}>
                                        Input your {(loginAs === "Student") ? "NIS" : "active Email"} to recovery your password
                                    </span>
                                </Row>
                                <Row style={{ marginLeft: 60, marginRight: 60, marginTop: 20, justifyContent: "start" }}>
                                    <div style={{ minWidth: "100%" }}>
                                        <Formik
                                            initialValues={{ nis: null, password: null }}
                                            validate={values => {
                                                const errors = {};
                                                if (loginAs === "Student") {
                                                    if (!values.nis) {
                                                        errors.nis = 'NIS is required!';
                                                    }
                                                } else {
                                                    if (!values.email) {
                                                        errors.email = 'Email is required!';
                                                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                                        errors.email = 'Wrong Email Format!';
                                                    }
                                                }

                                                return errors;
                                            }}
                                            onSubmit={(values, { setSubmitting }) => {
                                                setTimeout(() => {
                                                    console.log(values)
                                                    handleForgotPassword(values)
                                                    setSubmitting(false);
                                                }, 400);
                                            }}
                                        >
                                            {({ isSubmitting, values, setFieldValue, errors, validateField, handleSubmit }) => (
                                                <Form>
                                                    {(loginAs === "Student") ?
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <span style={{ fontWeight: "bold" }}>NIS</span>
                                                            <Input
                                                                // prefix={<MailOutlined />}
                                                                type="text"
                                                                placeholder="Type your NIS"
                                                                style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                                name="nis"
                                                                onChange={(e) => { setFieldValue("nis", e.target.value, true) }}
                                                                status={errors.nis && "error"}
                                                            />
                                                            <ErrorMessage name="nis" component="div" style={{ color: "red" }} />
                                                        </Row>
                                                        :
                                                        <Row style={{ marginBottom: 10 }}>
                                                            <span style={{ fontWeight: "bold" }}>Email</span>
                                                            <Input
                                                                // prefix={<MailOutlined />}
                                                                type="text"
                                                                placeholder="Type your Email"
                                                                style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                                name="email"
                                                                onChange={(e) => { setFieldValue("email", e.target.value, true) }}
                                                                status={errors.email && "error"}
                                                            />
                                                            <ErrorMessage name="email" component="div" style={{ color: "red" }} />
                                                        </Row>
                                                    }

                                                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                        <Button className="button-primary" type="submit" disabled={loading} loading={loading} onClick={handleSubmit}>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </Form>
                                            )}
                                        </Formik>
                                    </div>
                                </Row>
                            </Content>
                        </Col>
                        <Col span={12} className="desktop-only" style={{ width: "100%", height: "100vh", background: "#024102" }}>
                            <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
                                <img src="/image/login_bg.png" alt="background login" width="100%">
                                </img>
                            </div>
                        </Col>
                    </Row>
            }
        </Layout>
    );
}

export default (ForgotPassword)