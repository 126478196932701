import React from "react";
import {
    useNavigate
} from "react-router-dom";

let iconOLIN = "image/icon.png";

const TopBar = () => {
    let navigate = useNavigate();

    return (
        <div className="Topbar" style={{ zIndex: 1, display: "flex", height: 50, backgroundColor: "#FFF", borderBottom:"solid 1px", boxShadow: "1px 5px 10px #888888;", justifyContent: "space-between", alignItems: "center", padding: 30 }}>
            <img src={iconOLIN} alt="Icon Al-Irsyad" width={50} height={30}
                style={{ cursor: "pointer", margin: 5 }}
                onClick={() => { navigate("/login") }}
            />
        </div>
    );
}

export default (TopBar)