import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, Typography, Tooltip } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APIServices } from "../services";
import useWindowDimensions from "../utils/size-window";

const UpdatedSubject = (props) => {
    const navigate = useNavigate();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [listUpdate, setListUpdate] = useState([]);

    const getListUpdates = (limit) => {
        return APIServices.getUpdate(limit)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setListUpdate(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getListUpdates();
    }, [props])

    return (
        <div style={{background: "#FFF"}}>
            <Row className="topbar detail" style={{ width: "100%", paddingLeft: marginValue, marginRight: marginValue, }}>
                <ArrowLeftOutlined style={{ marginRight: 20 }} onClick={() => { navigate("/") }} />

                <div>
                    Updated Subject
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginBottom: 100 }} justify="space-between">
                <div style={{ width: "100%" }}>
                <Row justify="start" style={{marginTop: 128}}>
                        {listUpdate && listUpdate.map(item => (
                            <Col span={6} xs={12} sm={8} md={6}>
                                <Tooltip placement="bottom" title={item.mata_pelajaran + " - " + item.trigger_name}>
                                    <div className="card-mapel" 
                                        onClick={() => { 
                                            if(item.trigger === "video"){
                                                navigate(`/subject/${item.mata_pelajaran_id}/video/${item.trigger_id}`) 
                                            } else if(item.trigger === "modul") {
                                                navigate(`/subject/${item.mata_pelajaran_id}/learning-module/${item.trigger_id}`) 
                                            }
                                        }}
                                        style={{ justifyContent: "center", margin: 10, textAlign: "center", maxHeight: 150, cursor: "pointer" }}
                                    >
                                        <div style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                            <img src={item.logo ? process.env.REACT_APP_DIRECTORY + "/"+item.logo : (item.trigger === "video") ? "image/update_subject_video.png" : "image/update_subject.png"} alt="Update Subject"
                                                style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                            />
                                            <Typography.Text ellipsis={{ rows: 3 }} style={{ fontWeight: 500 }}>
                                                {item.mata_pelajaran + " - " + item.trigger_name}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Tooltip>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default (UpdatedSubject)