import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from 'formik';
import { Layout, Row, Col, Input, Button } from 'antd';

import { APIServices } from "../services";
import useWindowDimensions from "../utils/size-window";
import { ModalServerError, ModalSuccessReset } from "../components/Modal";

const ResetPassword = () => {
    let navigate = useNavigate();
    let location = useLocation();
    const dimension = useWindowDimensions();

    const [loading, setLoading] = useState(false)

    const [showModal, setShowModal] = useState(false);
    const [showModal2, setShowModal2] = useState(false);

    // const [errorMessage, setErrorMessage] = useState(null);

    const handleResetPass = (body) => {
        setLoading(true)
        let loc_split = location.pathname.split('/')
        let token = loc_split[2]
        let email = loc_split[3]
        body.token = token;
        body.email = email;

        localStorage.clear();
        APIServices.resetPassword(body)
            .then(res => {
                let response = res.data;
                if (response) {
                    setShowModal(true);
                    setTimeout(() => {
                        navigate("/login");
                    }, 5000)
                }
                setLoading(false)
            })
            .catch(err => {
                if (err?.request?.status === 500) {
                    setShowModal2(true);
                } else {
                    // setErrorMessage(err?.response?.data?.data?.error)
                    setShowModal(true);
                }
                console.log(err)

                setLoading(false)
            })
    }

    return (
        <Layout className="layout">
            <ModalSuccessReset visible={showModal} setShowModal={setShowModal} />
            <ModalServerError visible={showModal2} setShowModal={setShowModal2} />
            <Row justify="center" align="middle">
                <Col span={dimension.width > 800 ? 12 : 24} style={{ width: "100%", height: "100vh", background: "#FFF" }}>
                    <Row justify="center" align="middle" style={{ marginBottom: 5, marginTop: 50 }}>
                        <span style={{ textAlign: "center", color: "#024102", fontSize: "1.3rem", fontWeight: "bold" }}>
                            Change Password
                        </span>
                    </Row>
                    <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                        <span style={{ textAlign: "center", color: "#000", fontSize: "1rem" }}>
                            Change Password to recovery your account
                        </span>
                    </Row>
                    <Row justify="center">
                        <div style={{ minWidth: "80%" }}>
                            <Formik
                                initialValues={{ nis: null, password: null }}
                                validate={values => {
                                    const errors = {};

                                    if (!values.password) {
                                        errors.password = 'Password is required!';
                                    } else {
                                        if (values.password.length < 8) {
                                            errors.password = 'Password must be at least 8 characters.!';
                                        }
                                    }

                                    if (!values.password_confirmation) {
                                        errors.password_confirmation = 'Password Confirmation is required!';
                                    } else {
                                        if (values.password !== values.password_confirmation) {
                                            errors.password_confirmation = 'Password Confirmation not match!';
                                        }
                                    }

                                    return errors;
                                }}
                                onSubmit={(values, { setSubmitting }) => {
                                    setTimeout(() => {
                                        console.log(values)
                                        handleResetPass(values)
                                        setSubmitting(false);
                                    }, 400);
                                }}
                            >
                                {({ isSubmitting, values, setFieldValue, errors, validateField, handleSubmit }) => (
                                    <Form>
                                        <Row style={{ marginBottom: 5 }}>
                                            <span style={{ fontWeight: "bold" }}>New Password</span>
                                            <Input.Password
                                                // prefix={<LockOutlined />}
                                                type="password"
                                                placeholder="Type your Password"
                                                style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                name="password"
                                                onChange={(e) => { setFieldValue("password", e.target.value, true) }}
                                                onKeyPress={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleSubmit();
                                                    }
                                                }}
                                                status={errors.password && "error"}
                                            />
                                            <ErrorMessage name="password" component="div" style={{ color: "red" }} />
                                        </Row>
                                        <Row style={{ marginBottom: 5 }}>
                                            <span style={{ fontWeight: "bold" }}>Confirm Your New Password</span>
                                            <Input.Password
                                                // prefix={<LockOutlined />}
                                                type="password"
                                                placeholder="Confirm your Password"
                                                style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                name="password_confirmation"
                                                onChange={(e) => { setFieldValue("password_confirmation", e.target.value) }}
                                                status={errors.password_confirmation && "error"}
                                            />
                                            <span style={{ color: 'red' }}>{errors.password_confirmation || ""}</span>
                                        </Row>

                                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 40, marginBottom: 10 }}>
                                            <Button className="button-primary" type="submit" disabled={loading} loading={loading} onClick={handleSubmit}>
                                                Change Password
                                            </Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Row>
                </Col>
                <Col span={12} className="desktop-only" style={{ width: "100%", height: "100vh", background: "#024102" }}>
                    <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
                        <img src="/image/login_bg.png" alt="background login" width="100%">
                        </img>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default (ResetPassword)