import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL
const client = axios.create();
// axios.defaults.withCredentials = true

// // //Request interceptor
client.interceptors.request.use(
    function (config) {
        let token = JSON.parse(localStorage.getItem('token_siswa'));
        if (token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    function (err) {
        throw new Error(err);
    }
);

const APIServices = {
    /* AUTH */
    register(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/register`,
                data: data
            },
            { crossdomain: false }
        )
    },
    login(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/login`,
                data: data,
                headers: {
                    "Content-Type": "application/json",
                },
            },
            { crossdomain: false }
        )
    },
    verifyEmail(email) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/verify-email`,
                params: { "email": email },
                headers: {
                    "Content-Type": "application/json",
                },
            },
            { crossdomain: false }
        )
    },
    forgotPassword(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/password/forgot`,
                data: data
            },
            { crossdomain: false }
        )
    },
    forgotPasswordStudent(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/password/forgot/student`,
                data: data
            },
            { crossdomain: false }
        )
    },
    resetPassword(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/password/reset`,
                data: data
            },
            { crossdomain: false }
        )
    },

    /* PROFILE */
    getProfile(data) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/profile`,
            },
            { crossdomain: false }
        )
    },
    editProfile(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/profile`,
                data: data
            },
            { crossdomain: false }
        )
    },
    editPassword(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/profile/password`,
                data: data
            },
            { crossdomain: false }
        )
    },
    uploadPhoto(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/upload/photo`,
                data: data
            },
            { crossdomain: false }
        )
    },

    /* HOME */
    getBanner() {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/home/banners`,
            },
            { crossdomain: false }
        )
    },
    getUpdate(limit) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/home/updates`,
                params: { "limit": limit }
            },
            { crossdomain: false }
        )
    },

    /* JENJANG & TINGKAT */
    getJenjang() {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/jenjangs`,
            },
            { crossdomain: false }
        )
    },
    getJenjangByID(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/jenjangs/${id}`,
            },
            { crossdomain: false }
        )
    },
    getTingkat(jenjang_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/tingkats`,
                params: { "q_jenjang_id": jenjang_id }
            },
            { crossdomain: false }
        )
    },

    /* MATA PELAJARAN*/
    getMapel(tingkat_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/mata_pelajarans`,
                params: { "q_tingkat_id": tingkat_id }
            },
            { crossdomain: false }
        )
    },
    getMapelDetail(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/mata_pelajarans/${id}`,
                // params: {"q_tingkat_id": tingkat_id}
            },
            { crossdomain: false }
        )
    },
    getMapelSimulasi(tingkat_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/mata_pelajarans_simulasi`,
                params: { "q_tingkat_id": tingkat_id }
            },
            { crossdomain: false }
        )
    },

    /* MODUL */
    getModul(mapel_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/moduls`,
                params: { "q_mata_pelajaran_id": mapel_id }
            },
            { crossdomain: false }
        )
    },
    getModulDetail(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/moduls/${id}`,
                // params: { "q_mata_pelajaran_id": id }
            },
            { crossdomain: false }
        )
    },
    postModulFlag(id, data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/moduls/${id}/flag`,
                data: data
            },
            { crossdomain: false }
        )
    },

    /* VIDEO */
    getVideo(mapel_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/videos`,
                params: { "q_mata_pelajaran_id": mapel_id }
            },
            { crossdomain: false }
        )
    },
    getVideoDetail(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/videos/${id}`,
                // params: { "q_mata_pelajaran_id": id }
            },
            { crossdomain: false }
        )
    },
    postVideoFlag(id, data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/videos/${id}/flag`,
                data: data
            },
            { crossdomain: false }
        )
    },

    /* SIMULATION */
    getSimulation(mapel_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/simulasis`,
                params: { "q_mata_pelajaran_id": mapel_id }
            },
            { crossdomain: false }
        )
    },
    getSimulationDetail(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/simulasis/${id}`,
                // params: { "q_mata_pelajaran_id": id }
            },
            { crossdomain: false }
        )
    },
    postSimulationFlag(id, data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/simulasis/${id}/flag`,
                data: data
            },
            { crossdomain: false }
        )
    },
    postSimulationScore(id, data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/simulasis/${id}/score`,
                data: data
            },
            { crossdomain: false }
        )
    },
    getNilaiSimulasi(mapel_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/nilai-simulasi/mapels/${mapel_id}`,
            },
            { crossdomain: false }
        )
    },

    /* SOAL */
    getPaketSoal(mapel_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/paket-soal`,
                params: { "mata_pelajaran_id": mapel_id }
            },
            { crossdomain: false }
        )
    },
    getSoal(id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/soal`,
                params: { "paket_soal_id": id }
            },
            { crossdomain: false }
        )
    },
    getPembahasan(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/soal/studies`,
                data: data
            },
            { crossdomain: false }
        )
    },
    postJawaban(data) {
        return client.request(
            {
                method: "post",
                url: `${baseURL}/soal/check-answers`,
                data: data
            },
            { crossdomain: false }
        )
    },

    /* E-RAPORT */
    getERaport(mapel_id, user_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/e-raport`,
                params: { "mata_pelajaran_id": mapel_id, "user_id": user_id }
            },
            { crossdomain: false }
        )
    },
    getERaportSOL(mapel_id, user_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/e-raport/summary-of-level`,
                params: { "mata_pelajaran_id": mapel_id, "user_id": user_id }
            },
            { crossdomain: false }
        )
    },
    getERaportSA(tingkat_id, user_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/e-raport/subject-achievement`,
                params: { "tingkat_id": tingkat_id, "user_id": user_id }
            },
            { crossdomain: false }
        )
    },
    getERaportHA(tingkat_id, user_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/e-raport/home-achievement`,
                params: { "tingkat_id": tingkat_id, "user_id": user_id }
            },
            { crossdomain: false }
        )
    },
    getCurrentScore(limit, user_id) {
        return client.request(
            {
                method: "get",
                url: `${baseURL}/e-raport/current-score`,
                params: { "limit": limit, "user_id": user_id }
            },
            { crossdomain: false }
        )
    },
}

export { APIServices };