import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Layout, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";

const LearningSimulationDetail = (props) => {
    const navigate = useNavigate();
    const params = useParams();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [isLoading, setIsLoading] = useState(true);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [dataSimulation, setDataSimulation] = useState([]);

    const gridIframe = useRef(null);
    function handleIframe() {
        // setLoading(false);
        const iframeItem = gridIframe.current;
        const anchors = iframeItem.contentWindow.document.getElementsByTagName("a");

        console.log(iframeItem)
        console.log(anchors)
    }


    const data = JSON.parse(localStorage.getItem('data'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getDataSimulation = async (video_id) => {
        await APIServices.getSimulationDetail(video_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSimulation(response.data);
                    if (!subjectID) {
                        setSubjectID(response.data?.mata_pelajaran?.id);
                        getDetailMapel(response.data?.mata_pelajaran?.id);
                    }
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        let id = params.id;
        let sim_id = params.sim_id;

        if (id) {
            setSubjectID(id);
            getDetailMapel(id);
        }

        getDataSimulation(sim_id);
    }, [location])

    useEffect(() => {
        const x = document.querySelector("iframe")?.contentWindow;
        const sf = document.querySelector("simulasi-frame");
        console.log(sf)
        console.log(x);
        console.log(gridIframe)
    }, [gridIframe])

    // const getAttrs = (iframeTag) => {
    //     var doc = document.createElement('div');
    //     doc.innerHTML = iframeTag;

    //     const iframe = doc.getElementsByTagName('iframe')[0];
    //     return [].slice
    //       .call(iframe.attributes)
    //       .reduce((attrs, element) => {
    //         attrs[element.name] = element.value;
    //         return attrs;
    //       }, {});
    //   }

    return (
        <div style={{ background: "#FFF" }}>
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "start" }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/learning-simulation`) }} />
                        <span>Back to Learning Simulation List</span>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>
                            {dataSubject?.name ? dataSubject?.name + " - " + dataSimulation?.name : "Learning Simulation"}
                        </span>
                    </Row>
                </>
                :
                <Row>
                    <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", paddingLeft: 20 }}>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}/learning-simulation`) }} />

                            <div>
                                {dataSubject?.name ? dataSubject?.name + " - " + dataSimulation?.name : "Learning Simulation"}
                            </div>
                        </div>

                        <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>
                    </div>
                </Row>
            }

            {isLoading ?
                <Loading isChildren={true} />
                :
                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128 }}>
                    <iframe
                        id="simulasi-frame"
                        src={`${dataSimulation?.simulasi_url}?simulasi_id=${dataSimulation?.id}&url=${"/api/simulasis/"}&token=${data.token}`}
                        title={dataSimulation?.name}
                        width={dimension.width - (marginValue * 2)}
                        height={dimension.height - 250}
                        ref={gridIframe}
                        onLoad={handleIframe}
                    // {...getAttrs(props.iframe) }
                    />
                </Row>
            }
        </div>
    );
}

export default (LearningSimulationDetail)