import { AutoComplete as SelectAutoComplete, Input } from 'antd';
import { useField } from 'formik';

export function AutoComplete({
    name,
    placeholder,
    options,
    onValueChange,
    setFieldValue,
    callFunction,
    disabled,
    ...props
}){
    const [field] = useField(props)
    return (
        <SelectAutoComplete
            style={{width:"100%"}}
            options={options}
            value={field?.value[name]}
            onSelect={(value, option) => {
                setFieldValue(name, option, false);
            }}
            onClear={() => {setFieldValue(name, null, false);}}
            disabled={disabled}
            allowClear={false}
            filterOption={true}
            notFoundContent={`${placeholder} tidak ditemukan`}
        >
            <Input placeholder={placeholder} style={{ width: "100%", borderRadius: 5, padding: "10px", border: "solid 1px #D9D9D9" }}/>
        </SelectAutoComplete>
    )
}