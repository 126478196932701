import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Collapse } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";

const { Panel } = Collapse;

const AdaptiveLearning = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [isLoading, setIsLoading] = useState(true);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [listPaketSoal, setListPaketSoal] = useState([]);

    const data = JSON.parse(localStorage.getItem('data'));

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getListPaketSoal = async (mapel_id) => {
        await APIServices.getPaketSoal(mapel_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setListPaketSoal(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        let loc_split = location.pathname.split('/')
        let id = loc_split[2]
        setSubjectID(id);
        getDetailMapel(id);
        getListPaketSoal(id);

    }, [location])

    const handleKerjakanSoal = (paket_soal_id, judul) => {
        let obj = {
            user: data?.nis,
            paket_soal: paket_soal_id,
            judul: judul
        }
        localStorage.setItem('soal_sedang_dikerjakan', JSON.stringify(obj))
    }

    return (
        <div style={{ background: "#FFF" }}>
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}`) }} />
                        <span>Back to Subject Detail</span>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>{dataSubject?.name || "Adaptive Learning"}</span>
                    </Row>
                </>
                :
                <Row>
                    <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", paddingLeft: 20 }}>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}`) }} />

                            <div>
                                {dataSubject?.name || "Adaptive Learning"}
                            </div>
                        </div>

                        <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>
                    </div>
                </Row>
            }

            {isLoading ?
                <Loading isChildren={true} />
                :
                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128 }}>
                    <div style={{ minWidth: "100%" }}>

                        {listPaketSoal && listPaketSoal.length > 0 ?
                            <Collapse accordion expandIconPosition="right">
                                {listPaketSoal.map((item, index) => (
                                    <Panel key={index.toString()}
                                        header={
                                            <div className="learning-type">
                                                <img src={item.icon ? process.env.REACT_APP_DIRECTORY + "/"+ item.icon : "/image/LearningSubject.png"}
                                                    alt="Learning"
                                                    style={{ width: 60, height: 60 }}
                                                />
                                                <div style={{ marginLeft: 40 }}>
                                                    {item.name}
                                                </div>
                                            </div>
                                        }
                                        collapsible={(!item.is_public && !item.mapel_assigned && data.is_pengunjung) && "disabled"}
                                    >
                                        {item.paket_soal && item.paket_soal.map(soal => (
                                            <div onClick={() => {
                                                handleKerjakanSoal(soal.id, soal.judul_subbab)
                                                navigate(`/subject/${subjectID}/adaptive-learning/${soal.id}`)
                                            }}
                                                style={{ borderBottom: "solid 1px #EDEDED", marginTop: 15, marginLeft: 70, minHeight: 40, paddingBottom: 10, cursor: "pointer" }}>
                                                <span>{soal.judul_subbab}</span>
                                            </div>
                                        ))}
                                    </Panel>
                                ))
                                }

                            </Collapse>
                            :
                            <div className="card" style={{ display: "block", textAlign: "center", padding: 20 }}>
                                <img src={"/image/Empty.png"} alt="More"
                                    style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 250, height: 200 }}
                                />
                                <div style={{ fontWeight: "bold", fontSize: 25 }}>
                                    Adaptive Learning Empty
                                </div>
                                <div>
                                    We're sorry, there's no adaptive learning for this subject. <br />
                                    Please check again later
                                </div>
                            </div>
                        }
                    </div>
                </Row>
            }
        </div>
    );
}

export default (AdaptiveLearning)