import React, { useState } from "react";
import { Row, Input } from 'antd';
import {
    EditOutlined
} from '@ant-design/icons';
import Auth from "../services/Auth";
import { ModalLogout } from "../components/Modal";
import useWindowDimensions from "../utils/size-window";
import { useNavigate } from "react-router-dom";
import { ModalUploadPhoto } from "../components/ModalUploadPhoto";

let profile = "image/Profile.png";

const Profile = (props) => {
    let navigate = useNavigate();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    const [showModal, setShowModal] = useState(false);
    const [showModalUpload, setShowModalUpload] = useState(false);

    return (
        <div style={{ background: "#FFF" }}>
            <ModalUploadPhoto visible={showModalUpload} setShowModal={setShowModalUpload}/>
            <ModalLogout visible={showModal} setShowModal={setShowModal} logout={Auth.logout} />
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128 }}>
                <div style={{ display: "flex", justifyContent: "center", minWidth: "100%" }}>
                    <img src={props.data?.photo || profile} alt="Profile Icon" width={200} height={200}
                        style={{ cursor: "pointer", marginLeft: "auto", marginRight: "auto", borderRadius: "50%", objectFit: "cover", border: "solid 1px #000" }}
                    />
                    <div style={{ position: "absolute", width: 200, height: 200, display: "flex", justifyContent: "end", alignItems: "end" }}>
                        <EditOutlined
                            style={{
                                fontSize: 30, paddingTop: 3,
                                borderRadius: "50%", border: "solid 4px #FFF",
                                backgroundColor: "#E98A15", color: "#FFF", width: 40, height: 40,
                                cursor: "pointer"
                            }}
                            onClick={() => {setShowModalUpload(true)}}
                        />
                    </div>
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <div style={{ width: "100%", maxWidth: 600, display: "block" }}>
                    <div style={{ display: "flex", marginBottom: 20 }}>
                        <div
                            style={{
                                width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20,
                                borderRadius: "50%", border: "solid 4px #FFF",
                                backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/information.png`} alt="Info"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED", cursor: "pointer" }}
                            onClick={() => navigate("/personal-information")}
                        >
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#616161" }}>
                                        Personal Information
                                    </div>
                                    <div>NIS, Name, Password, Class</div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div div style={{ display: "flex", marginBottom: 20 }}>
                        <div 
                            style={{ 
                                width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20,
                                borderRadius: "50%", border: "solid 4px #FFF",
                                backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/privacy.png`} alt="Privacy"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED", cursor: "pointer" }}
                            onClick={() => navigate("/privacy-policy")}
                        >
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#616161" }}>
                                        Privacy & Policy
                                    </div>
                                    <div>All Privacy & Policy about this web</div>
                                </div>
                                <div style={{ marginTop: 20 }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div div style={{ display: "flex", marginBottom: 20 }}>
                        <div 
                            style={{
                                 width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center", marginRight: 20, 
                                 borderRadius: "50%", border: "solid 4px #FFF",
                                 backgroundColor: "rgba(10, 188, 93, 0.1)", color: "#FFF", width: 60, height: 60,
                            }}
                        >
                            <img src={`/image/lock.png`} alt="Password"
                                style={{ cursor: "pointer", width: 30, height: 30, marginLeft: "auto", marginRight: "auto", paddingTop: 13 }}
                            />
                        </div>
                        <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 1px #EDEDED", cursor: "pointer" }}
                            onClick={() => navigate("/change-password")}
                        >
                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                <div style={{ display: "block" }}>
                                    <div style={{ color: "#616161" }}>
                                        Password
                                    </div>
                                    <div>Change Password</div>
                                </div>
                                <div style={{ marginTop: 20, fontSize: 20, alignItems: "center" }}>
                                    →
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: 20, marginBottom: (dimension.width <= 1210) ? 120 : 40 }}>
                        <button className="button-danger" type="button" onClick={() => { setShowModal(true) }}>
                            Logout
                        </button>
                    </div>
                </div>
            </Row>
        </div>
    );
}

export default (Profile)