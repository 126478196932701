import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, Carousel, Typography, Progress, Tooltip } from 'antd';
import { APIServices } from "../services";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";

const Home = (props) => {
    const navigate = useNavigate();
    const dimension = useWindowDimensions();
    const [isRender, setIsRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [listBanner, setListBanner] = useState([]);
    const [tingkatSelected, setTingkatSelected] = useState(1);
    const [listTingkat, setListTingkat] = useState([]);
    const [listMapel, setListMapel] = useState([]);
    const [listUpdate, setListUpdate] = useState([]);
    const [homeAchievement, setHomeAchievement] = useState({});

    const getListBanner = async () => {
        setIsLoading(true);
        await APIServices.getBanner()
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setListBanner(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getListTingkat = async (jenjang_id) => {
        setIsLoading(true);
        await APIServices.getTingkat(jenjang_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                    setListTingkat(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getListMapel = async (tingkat_id) => {
        setIsLoading(true);
        await APIServices.getMapel(tingkat_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setListMapel(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getListUpdates = (limit) => {
        setIsLoading(true);
        return APIServices.getUpdate(limit)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setListUpdate(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getHomeAchievement = (tingkat_id, user_id) => {
        setIsLoading(true);
        return APIServices.getERaportHA(tingkat_id, user_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setHomeAchievement(response.data)
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (isRender) {
            let defaultTingkat = {"SD": 1, "SMP": 7, "TK": 10}
            setTingkatSelected(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang]);

            getListTingkat(props.data?.jenjang_id);
            getListMapel(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang]);
            getListBanner();
            getListUpdates(4);
            getHomeAchievement(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang], props.data?.user_id)

            setIsRender(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    const handleSelectTingkat = (item) => {
        setTingkatSelected(item.id);
        getListMapel(item.id);
    }

    let marginValue = (dimension.width > 800) ? 60 : 20

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div style={{background:"#FFF"}}>
            <Carousel style={{ marginTop: 108 }} dotPosition="right" autoplay>
                {listBanner && listBanner.map(item => (
                    dimension.width > 800 ?
                        <div >
                            <div style={{ overflow: "hidden", maxHeight: "720px", textAlign: "right" }}>
                                <img src={process.env.REACT_APP_DIRECTORY + "/" + item.image} alt={item.title}
                                    style={{ cursor: "pointer", marginRight: 20, width: dimension.width }}
                                />
                            </div>
                        </div>
                        :
                        <div>
                            <img src={process.env.REACT_APP_DIRECTORY + "/" + item.image} alt={item.title}
                                style={{ cursor: "pointer", marginRight: 10, width: "100%" }}
                            />
                        </div>
                ))}
            </Carousel>

            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: -30 }} align="middle">
                <div className="card" style={{ width: "100%", display: "flex", zIndex: 2, height: 80, justifyContent: "space-between" }}>
                    <div style={{ width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center" }}>
                        <img src={"image/thropy.png"} alt="Achievement Progress"
                            style={{ cursor: "pointer", width: 50, height: 50, marginLeft: "auto", marginRight: "auto" }}
                        />
                    </div>
                    <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto" }}>
                        <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                            <span>Your Achievement</span>
                            <span style={{ color: "#E98A15" }}>{homeAchievement.total_benar}/{homeAchievement.total_terjawab}</span>
                        </div>
                        <Progress percent={homeAchievement.percentage} strokeColor={{ from: "#F5AF19", to: "#F12711", direction: "270deg" }} showInfo={false} />
                    </div>
                </div>
            </Row>

            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 30 }}>
                <span style={{ display: "block", color: "#0A0A0A", fontSize: 18, fontWeight: "bold" }}>
                    Subject of Class
                </span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, }}>
                <div style={{ display: "flex", minWidth: "100%", maxWidth: "100%", overflowY: "auto" }}>
                    {listTingkat && listTingkat.map(item => (
                        <div className={`card-kelas ${tingkatSelected === item.id && "active"}`} style={{ width: `${100 / listTingkat.length}%` }}
                            onClick={() => { handleSelectTingkat(item) }}>
                            {item.name + " " + item.jenjang.name}
                        </div>
                    ))}
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20 }}>
                <div className="card-mapel" style={{ width: "100%" }}>
                    <Row justify="start">
                        {listMapel && listMapel.map((item, index) => {
                            if (dimension.width > 800) {
                                return (
                                    <Col span={4} xs={8} sm={6} md={4} lg={3} style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                        <div style={{ cursor: "pointer" }} onClick={() => { navigate(`/subject/${item.id}`) }}>
                                            <img src={process.env.REACT_APP_DIRECTORY + "/" + item.icon} alt={item.name}
                                                style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                            />
                                            <span style={{ display: "block", fontWeight: 600 }}>
                                                {item.name}
                                            </span>
                                        </div>
                                    </Col>
                                )
                            } else {
                                if (index < 11 || listMapel.length < 13) {
                                    return (
                                        <Col span={4} xs={8} sm={6} md={4} lg={3} style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                            <div style={{ cursor: "pointer" }} onClick={() => { navigate(`/subject/${item.id}`) }}>
                                                <img src={process.env.REACT_APP_DIRECTORY + "/" + item.icon} alt={item.name}
                                                    style={{ display: "block", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                                />
                                                <span style={{ display: "block", fontWeight: 600 }}>
                                                    {item.name}
                                                </span>
                                            </div>
                                        </Col>
                                    )
                                } else {
                                    return (
                                        (index === 11) &&
                                        <Col span={4} xs={8} sm={6} md={4} lg={3} style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                            <div onClick={() => { navigate(`/subject`) }}>
                                                <img src={"image/More.png"} alt="More"
                                                    style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                                />
                                                <span style={{ display: "block", fontWeight: 600 }}>
                                                    More
                                                </span>
                                            </div>
                                        </Col>
                                    )
                                }
                            }
                        })}
                    </Row>
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20 }} justify="space-between">
                <div style={{ width: "50%", color: "#0A0A0A", fontSize: 18, fontWeight: "bold" }}>
                    Updated Subject
                </div>
                <div style={{ width: "50%", color: "#E98A15", fontSize: 18, fontWeight: "bold", cursor: "pointer", textAlign: "right" }} onClick={() => { navigate("/updated-subject") }}>
                    See All
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginBottom: (dimension.width <= 1210) ? 120 : 40 }} justify="space-between">
                <div style={{ width: "100%" }}>
                    <Row justify="start">
                        {listUpdate && listUpdate.map(item => (
                            <Col span={6} xs={12} sm={8} md={6}>
                                <Tooltip placement="bottom" title={item.mata_pelajaran + " - " + item.trigger_name}>
                                    <div className="card-mapel" 
                                        onClick={() => { 
                                            if(item.trigger === "video"){
                                                navigate(`/subject/${item.mata_pelajaran_id}/video/${item.trigger_id}`) 
                                            } else if(item.trigger === "modul") {
                                                navigate(`/subject/${item.mata_pelajaran_id}/learning-module/${item.trigger_id}`) 
                                            }
                                        }}
                                        style={{ justifyContent: "center", margin: 10, textAlign: "center", maxHeight: 150, cursor: "pointer" }}
                                    >
                                        <div style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                            <img src={item.logo ? process.env.REACT_APP_DIRECTORY + "/"+item.logo : (item.trigger === "video") ? "image/update_subject_video.png" : "image/update_subject.png"} alt="Update Subject"
                                                style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 70, height: 70 }}
                                            />
                                            <Typography.Text ellipsis={{ rows: 3 }} style={{ fontWeight: 500 }}>
                                                {item.mata_pelajaran + " - " + item.trigger_name}
                                            </Typography.Text>
                                        </div>
                                    </div>
                                </Tooltip>
                            </Col>
                        ))}
                    </Row>
                </div>
            </Row >
        </div >
    );
}

export default (Home)