import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from 'antd';
import { APIServices } from "../services";
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import moment from "moment";
import Loading from "../components/LoadingScreen";
import useWindowDimensions from "../utils/size-window";
import { ModalAccessDenied } from "../components/Modal";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    zoomPlugin,
    ChartDataLabels
);
ChartJS.defaults.scales.linear.min = 0;
ChartJS.defaults.scales.linear.max = 100;

const Report = (props) => {
    const navigate = useNavigate();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    const localData = JSON.parse(localStorage.getItem('data'))

    const [isLoading, setIsLoading] = useState(true);

    const [listScore, setListScore] = useState([]);
    const [dataSA, setDataSA] = useState([]);

    const getERaportSA = async (tingkat_id, user_id) => {
        setIsLoading(true);
        await APIServices.getERaportSA(tingkat_id, user_id)
            .then(res => {
                setIsLoading(false);
                let response = res.data;
                if (response) {
                    setDataSA(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getCurrentScore = async (user_id) => {
        await APIServices.getCurrentScore(3, user_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setListScore(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getERaportSA(props.data?.tingkat_id, props.data?.user_id)
        getCurrentScore(props.data?.user_id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const options = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
                min: 0,
                max: 100
            }
        },
        plugins: {
            legend: {
                display: true,
            },
            zoom: {
                pan: {
                    enabled: true,
                    mode: 'x'
                },
                zoom: {
                    // pinch: {
                    //     enabled: true       // Enable pinch zooming
                    // },
                    wheel: {
                        enabled: true,       // Enable wheel zooming
                        modifierKey: "ctrl"
                    },
                    drag: {
                        enabled: true,       // Enable wheel zooming
                    },
                    mode: 'x',
                    // limits: {
                    //     y: { min: 50, max: 100 },
                    //     x: { min: -5, max: 5 }
                    // },
                }
            },
            datalabels: {
                color: 'black',
                display: function (context) {
                    return context.dataset.data[context.dataIndex] > 0;
                },
                font: {
                    weight: 'bold'
                },
                formatter: Math.round
            }
        },
        // bezierCurve: true,
        // lineTension: 0.5
    };

    const data = {
        labels: dataSA.map((item) => item.mata_pelajaran),
        datasets: [
            {
                label: 'Easy',
                data: dataSA.map((item) => item.mudah?.percentage),
                pointBackgroundColor: "#75450B",
                pointBorderColor: "#FFF",
                pointRadius: 0,
                borderColor: '#0ABC5D',
                backgroundColor: '#0ABC5D',
                borderRadius: 30,
                minBarLength: 7,
            },
            {
                label: 'Medium',
                data: dataSA.map((item) => item.sedang?.percentage),
                pointBackgroundColor: "#75450B",
                pointBorderColor: "#FFF",
                pointRadius: 0,
                borderColor: '#FFAB05',
                backgroundColor: '#FFAB05',
                borderRadius: 30,
                minBarLength: 7,
            },
            {
                label: 'Hard',
                data: dataSA.map((item) => item.sulit?.percentage),
                pointBackgroundColor: "#75450B",
                pointBorderColor: "#FFF",
                pointRadius: 0,
                borderColor: '#C52421',
                backgroundColor: '#C52421',
                borderRadius: 30,
                minBarLength: 7,
            },
        ],
    };

    function App() {
        return <Bar options={options} data={data} height={400} width={dimension.width - (2*marginValue)} />;
    }

    const levelObject = {
        mudah: {
            label: "Easy Level",
            color: '#0ABC5D'
        },
        sedang: {
            label: "Medium Level",
            color: '#FFAB05'
        },
        sulit: {
            label: "Hard Level",
            color: '#C52421'
        }
    }

    if(isLoading){
        return (<Loading/>)
    }
    
    if(localData.is_pengunjung){
        setTimeout(() => {
            navigate("/")
        }, 1000)
        return <ModalAccessDenied visible={true}/>
    }

    return (
        <div style={{background: "#FFF"}}>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128 }}>
                <div style={{ display: "block", color: "#0A0A0A", fontSize: 18, fontWeight: "bold" }}>
                    Subject Achievement
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20 }} justify="center">
                <div className="card" style={{ display: "block", padding: 20, width: "100%", overflowX: "auto" }}>
                    <App />
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20 }} justify="space-between">
                <div style={{ width: "50%", color: "#0A0A0A", fontSize: 18, fontWeight: "bold" }}>
                    Your Score
                </div>
                <div style={{ width: "50%", color: "#E98A15", fontSize: 18, fontWeight: "bold", cursor: "pointer", textAlign: "right" }} onClick={() => { navigate("/e-report/detail") }}>
                    See All
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, marginBottom: (dimension.width <= 1210) ? 120 : 40 }} justify="space-between">
                <div style={{ minWidth: "100%" }}>

                    {listScore && listScore.length > 0 ?
                        listScore.map((item, index) => (
                            <div className="card" 
                                style={{ width: "100%", display: "flex", zIndex: 2, height: 80, justifyContent: "space-between", marginBottom: 10, cursor:"pointer" }}
                                onClick={() => { 
                                    localStorage.setItem('mapel_selected', JSON.stringify(item.mata_pelajaran_id))
                                    navigate("/e-report/detail");  
                                }}
                            >
                                <div style={{ width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center" }}>
                                    <img src={`${process.env.REACT_APP_DIRECTORY}/${item.mata_pelajaran_icon}`} alt="Current Score"
                                        style={{ cursor: "pointer", width: 50, height: 50, marginLeft: "auto", marginRight: "auto" }}
                                    />
                                </div>
                                <div style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto" }}>
                                    <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ display: "block" }}>
                                            <div style={{ color: "#616161" }}>
                                                {item.mata_pelajaran_name} &nbsp; - &nbsp;
                                                <span style={{ color: levelObject[item.tingkat_kesulitan]?.color }}>
                                                    {levelObject[item.tingkat_kesulitan]?.label}
                                                </span>
                                            </div>
                                            <div>{item.title}</div>
                                            <div style={{ color: "#9E9E9E" }}>{moment(item.last_updated).format("dddd, DD MMMM YYYY")}</div>
                                        </div>
                                        <div style={{marginTop:20}}>
                                            <span style={{ color: "#3C5BFF" }}>{item.total_benar}</span>
                                            <span  style={{ color: "#9E9E9E" }}>{"/" + item.total_terjawab}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <></>
                    }
                </div>
            </Row>
        </div>
    );
}

export default (Report)