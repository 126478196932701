import React, { useEffect, useState } from "react";
import { Row, Col, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Formik, Form } from 'formik';
import { APIServices } from "../services";
import { useNavigate, useLocation } from "react-router-dom";
import Loading from "../components/LoadingScreen";
import useWindowDimensions from "../utils/size-window";

import Auth from "../services/Auth";

const Subject = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const dimension = useWindowDimensions();
    const [isRender, setIsRender] = useState(true);
    const [isLoading, setIsLoading] = useState(true);

    const [tingkatSelected, setTingkatSelected] = useState(1);
    const [listTingkat, setListTingkat] = useState([]);

    const [initialListMapel, setInitialListMapel] = useState([]);
    const [listMapel, setListMapel] = useState([]);

    const getListTingkat = async (jenjang_id) => {
        setIsLoading(true);
        await APIServices.getTingkat(jenjang_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.id > b.id) ? 1 : -1)
                    setListTingkat(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    const getListMapel = async (tingkat_id) => {
        setIsLoading(true);
        await APIServices.getMapel(tingkat_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setInitialListMapel(response.data);
                    setListMapel(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
                setIsLoading(false);
            })
    }

    useEffect(() => {
        if (isRender) {
            let defaultTingkat = { "SD": 1, "SMP": 7, "TK": 10 }
            setTingkatSelected(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang]);

            getListTingkat(props.data?.jenjang_id);
            getListMapel(props.data?.tingkat_id || defaultTingkat[props.data?.jenjang]);

            setIsRender(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        if (!Auth.isLogin()) {
            navigate("/login")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    const handleSelectTingkat = (item) => {
        setTingkatSelected(item.id);
        getListMapel(item.id);
    }

    if (isLoading) {
        return (<Loading />)
    }

    return (
        <div style={{ background: "#FFF" }}>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, marginTop: 128 }}>
                <div style={{ minWidth: "100%" }}>
                    <Formik
                        initialValues={{ subject: "" }}
                        onSubmit={(values, { setSubmitting }) => {
                            setTimeout(() => {
                                console.log(values)
                                setSubmitting(false);
                            }, 400);
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Row style={{ marginBottom: 10 }}>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Search Subject"
                                        suffix={<SearchOutlined />}
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="subject"
                                        autoComplete="off"
                                        onChange={(e) => {
                                            setFieldValue("subject", e.target.value)

                                            let search = e.target.value?.toLowerCase();
                                            let arr = initialListMapel.filter(item => item.name.toLowerCase().indexOf(search) > -1);
                                            if (e.target.value) {
                                                setListMapel(arr)
                                            } else {
                                                setListMapel(initialListMapel)
                                            }
                                        }}
                                    />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Row>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, }}>
                <div style={{ display: "flex", width: "100%", overflowY: "auto" }}>
                    {listTingkat && listTingkat.map(item => (
                        <div className={`card-kelas ${tingkatSelected === item.id && "active"}`} style={{ width: `${100 / listTingkat.length}%` }}
                            onClick={() => { handleSelectTingkat(item) }} >
                            {item.name + " " + item.jenjang.name}
                        </div>
                    ))}
                </div>
            </Row>
            <Row style={{ marginLeft: (dimension.width > 450) ? 60 : 20, marginRight: (dimension.width > 450) ? 60 : 20, marginTop: 20, marginBottom: (dimension.width <= 1210) ? 120 : 40 }}>
                <div className="card-mapel" style={{ width: "100%" }}>
                    <Row justify="start">
                        {listMapel && listMapel.map((item, index) => {
                            return (
                                <Col xs={8} sm={8} md={6} lg={4} xl={3} style={{ justifyContent: "center", marginTop: 10, textAlign: "center" }}>
                                    <div style={{ cursor: "pointer" }} onClick={() => { navigate(`/subject/${item.id}`) }}>
                                        <img className="image-subject"
                                            src={process.env.REACT_APP_DIRECTORY + "/" + item.icon} alt={item.name}
                                            style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
                                        />
                                        <span style={{ display: "block", fontWeight: 600 }}>
                                            {item.name}
                                        </span>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </Row>
        </div>
    );
}

export default (Subject)