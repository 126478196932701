import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Layout, Row, Col, Input, Button } from 'antd';

import { Formik, Form, Field, ErrorMessage } from 'formik';
import { AutoComplete } from "../components/AutoComplete";

import TopBar from "../components/TopBar";
import { APIServices } from "../services";
import { useEffect } from "react";
import useWindowDimensions from "../utils/size-window";
import { ModalFailedRegister, ModalSuccessRegister } from "../components/Modal";

const { Content } = Layout;

const Register = (props) => {
    let navigate = useNavigate();
    const dimension = useWindowDimensions();
    const loginAs = props.loginAs

    const [showModal, setShowModal] = useState(false)
    const [showModalSuccess, setShowModalSuccess] = useState(false)
    const [listJenjang, setListJenjang] = useState([])

    const getListJenjang = () => {
        APIServices.getJenjang()
            .then(res => {
                if (res.data) {
                    let arr = [];

                    res.data.data.forEach(item => {
                        arr.push({
                            id: item.id,
                            value: item.name,
                            label: item.name
                        })
                    });

                    setListJenjang(arr);
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getListJenjang();
    }, [])

    const [errorEmail, setErrorEmail] = useState("")

    const [loading, setLoading] = useState(false)
    const handleRegister = (body) => {
        body.source_api_call = "web";
        setLoading(true)
        APIServices.register(body)
            .then(res => {
                console.log(res)
                setLoading(false)
                setShowModalSuccess(true)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setErrorEmail("The email has already been taken!")
                setShowModal(true);
            })
    }

    return (
        <Layout className="layout">
            <ModalFailedRegister visible={showModal} setShowModal={setShowModal} />
            <ModalSuccessRegister visible={showModalSuccess} setShowModal={setShowModalSuccess} />
            <Row justify="center" align="middle">
                <Col span={dimension.width > 800 ? 12 : 24} style={{ width: "100%", height: "100vh", background: "#FFF" }}>
                    <TopBar />
                    <Content>
                        <Row justify="center" align="middle" style={{ marginBottom: 5, marginTop: 20 }}>
                            <span style={{ textAlign: "center", color: "#024102", fontSize: "1.3rem", fontWeight: "bold" }}>
                                Assalamu'alaikum
                            </span>
                        </Row>
                        <Row justify="center" align="middle" style={{ marginBottom: 40 }}>
                            <span style={{ textAlign: "center", color: "#000", fontSize: "1rem" }}>
                                Please fill this form to create a new account <b>as {loginAs}</b>
                            </span>
                        </Row>
                        <Row justify="center">
                            <div style={{ minWidth: "80%", maxWidth: "80%" }}>
                                <Formik
                                    initialValues={{ name: '', phone: '', email: '', password: '', password_confirmation: '' }}
                                    validate={values => {
                                        const errors = {};
                                        if (loginAs === "Student") {
                                            if (!values.nis) {
                                                errors.nis = 'NIS is required!';
                                            }
                                        }

                                        if (!values.email) {
                                            errors.email = 'Email is required!';
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                                            errors.email = 'Wrong Email Format!';
                                        }

                                        if (!values.name) {
                                            errors.name = 'Full Name is required!';
                                        }

                                        if (!values.phone) {
                                            errors.phone = 'Phone is required!';
                                        }

                                        if (!values.jenjang?.id) {
                                            errors.jenjang = 'Level is required!';
                                        }

                                        if (!values.password) {
                                            errors.password = 'Password is required!';
                                        } else {
                                            if (values.password.length < 8) {
                                                errors.password = 'Password must be at least 8 characters.!';
                                            }
                                        }

                                        if (!values.password_confirmation) {
                                            errors.password_confirmation = 'Password Confirmation is required!';
                                        } else {
                                            if (values.password !== values.password_confirmation) {
                                                errors.password_confirmation = 'Password Confirmation not match!';
                                            }
                                        }

                                        return errors;
                                    }}
                                    onSubmit={(values, { setSubmitting }) => {
                                        setTimeout(() => {
                                            console.log(values)
                                            let body = {
                                                "name": values.name,
                                                "nis": values.nis,
                                                "email": values.email,
                                                "phone": values.phone,
                                                "password": values.password,
                                                "password_confirmation": values.password_confirmation,
                                                "jenjang_id": values.jenjang.id,
                                                "is_pengunjung": (loginAs === "Student") ? 0 : 1
                                                // "source_api_call": "ios"
                                            }
                                            handleRegister(body)
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >
                                    {({ isSubmitting, values, setFieldValue, errors, handleSubmit }) => (
                                        <Form>
                                            <Row style={{ marginBottom: 10 }}>
                                                <span style={{ fontWeight: "bold" }}>Full Name</span>
                                                <Input
                                                    // prefix={<MailOutlined />}
                                                    type="text"
                                                    placeholder="Type your Full Name"
                                                    style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                    name="name"
                                                    onChange={(e) => { setFieldValue("name", e.target.value) }}
                                                    status={errors.name && "error"}
                                                />
                                                <span style={{ color: 'red' }}>{errors.name || ""}</span>
                                            </Row>
                                            {loginAs === "Student" &&
                                                <Row style={{ marginBottom: 10 }}>
                                                    <span style={{ fontWeight: "bold" }}>NIS</span>
                                                    <Input
                                                        // prefix={<MailOutlined />}
                                                        type="text"
                                                        placeholder="Type your NIS"
                                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                        name="nis"
                                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                                        status={errors.nis && "error"}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.nis || ""}</span>
                                                </Row>
                                            }
                                            <Row style={{ marginBottom: 10 }}>
                                                <span style={{ fontWeight: "bold" }}>Email</span>
                                                <Input
                                                    // prefix={<MailOutlined />}
                                                    type="text"
                                                    placeholder="Type your Email"
                                                    style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                    name="email"
                                                    onChange={(e) => {
                                                        setFieldValue("email", e.target.value);
                                                        setErrorEmail("");
                                                    }}
                                                    status={errors.email && "error"}
                                                />
                                                <span style={{ color: 'red' }}>{errors.email || errorEmail}</span>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <span style={{ fontWeight: "bold" }}>Phone Number</span>
                                                <Input
                                                    // prefix={<MailOutlined />}
                                                    type="text"
                                                    placeholder="Type your Phone number"
                                                    style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                    name="phone"
                                                    onChange={(e) => { setFieldValue("phone", e.target.value) }}
                                                    status={errors.phone && "error"}
                                                />
                                                <span style={{ color: 'red' }}>{errors.phone || ""}</span>
                                            </Row>
                                            <Row style={{ marginBottom: 10 }}>
                                                <Col span={12} xs={24} md={12} style={{ paddingRight: (dimension.width > 800) && 10, marginBottom: (dimension.width < 800) && 10 }}>
                                                    <span style={{ fontWeight: "bold" }}>Password</span>
                                                    <Input.Password
                                                        // prefix={<LockOutlined />}
                                                        type="password"
                                                        placeholder="Type your Password"
                                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                        name="password"
                                                        onChange={(e) => { setFieldValue("password", e.target.value) }}
                                                        status={errors.password && "error"}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.password || ""}</span>
                                                </Col>
                                                <Col span={12} xs={24} md={12} style={{ paddingLeft: (dimension.width > 800) && 10 }}>
                                                    <span style={{ fontWeight: "bold" }}>Confirm Password</span>
                                                    <Input.Password
                                                        // prefix={<LockOutlined />}
                                                        type="password"
                                                        placeholder="Confirm your Password"
                                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                                        name="password_confirmation"
                                                        onChange={(e) => { setFieldValue("password_confirmation", e.target.value) }}
                                                        status={errors.password_confirmation && "error"}
                                                    />
                                                    <span style={{ color: 'red' }}>{errors.password_confirmation || ""}</span>
                                                </Col>
                                            </Row>

                                            <Row style={{ marginBottom: 20 }}>
                                                <span style={{ fontWeight: "bold" }}>Level</span>
                                                <Field
                                                    component={() => (
                                                        <AutoComplete
                                                            name="jenjang"
                                                            placeholder="Choose your Level"
                                                            options={listJenjang}
                                                            setFieldValue={setFieldValue}
                                                            status={errors.jenjang && "error"}
                                                        />
                                                    )}
                                                />
                                                <span style={{ color: 'red' }}>{values.jenjang || !errors.jenjang ? "" : "Level is required!"}</span>
                                            </Row>

                                            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                                                <Button className="button-primary" type="submit" disabled={loading} loading={loading} onClick={handleSubmit}>
                                                    Register
                                                </Button>
                                            </div>

                                            <div style={{ maxWidth: "100%", display: "flex", marginBottom: 10 }}>
                                                <hr />
                                                <span style={{ padding: 5 }}>or</span>
                                                <hr />
                                            </div>

                                            <div style={{ display: "flex", justifyContent: "space-between" }} onClick={() => navigate("/login")}>
                                                <button className="button-secondary" type="button" disabled={isSubmitting}>
                                                    Login
                                                </button>
                                            </div>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </Row>
                    </Content>
                </Col>
                <Col span={12} className="desktop-only" style={{ width: "100%", height: "100vh", background: "#024102" }}>
                    <div style={{ display: "flex", height: "100vh", alignItems: "center" }}>
                        <img src="/image/login_bg.png" alt="background login" width="100%">
                        </img>
                    </div>
                </Col>
            </Row>
        </Layout>
    );
}

export default (Register)