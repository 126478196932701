import React, { useEffect, useState } from "react";
import { Row, Progress } from 'antd';
import { APIServices } from "../services";
import { ModalAccessDenied } from "../components/Modal";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "../utils/size-window";
import moment from "moment";

const Achievement = (props) => {
    let navigate = useNavigate();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;
    let sizeImage = (dimension.width > 450) ? 60 : 50;
    const localData = JSON.parse(localStorage.getItem('data'))

    const [listMapel, setListMapel] = useState([]);
    const [listScore, setListScore] = useState([]);
    const [mapelSelected, setMapelSelected] = useState();
    const [mapelTitleSelected, setMapelTitleSelected] = useState();

    const getListMapelSimulasi = async (tingkat_id) => {
        await APIServices.getMapelSimulasi(tingkat_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    response.data.sort((a, b) => (a.urutan > b.urutan) ? 1 : -1)
                    setMapelSelected(response.data[0].id);
                    setMapelTitleSelected(response.data[0].name);
                    getListScoreMapel(response.data[0].id);
                    setListMapel(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getListScoreMapel = async (mapel_id) => {
        await APIServices.getNilaiSimulasi(mapel_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setListScore(response.data);
                }
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        getListMapelSimulasi(props.data?.tingkat_id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleSelectMapel = (item) => {
        setMapelSelected(item.id);
        setMapelTitleSelected(item.name);
        getListScoreMapel(item.id);
    }

    if (localData?.is_pengunjung) {
        setTimeout(() => {
            navigate("/")
        }, 1000)
        return <ModalAccessDenied visible={true} />
    }

    return (
        <div style={{ background: "#FFF" }}>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128 }}>
                <div style={{ display: "flex", width: "100%", overflowY: "auto" }}>
                    {listMapel && listMapel.map(item => (
                        <div className={`card-kelas ${mapelSelected === item.id && "active"}`} style={{ width: `${100 / listMapel.length}%`, minWidth: 120 }}
                            onClick={() => { handleSelectMapel(item) }} >
                            {item.name}
                        </div>
                    ))}
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginBottom: 20 }} align="middle">
                <div className="card" style={{ minWidth: "100%", display: "flex", zIndex: 2, justifyContent: "space-between" }}>
                    <div style={{ width: "95%", margin: "auto" }}>
                        <div style={{ fontWeight: 500, fontSize: 20, marginBottom: 10 }}>
                            Achievement of {mapelTitleSelected}
                        </div>
                        <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                            <span>Finished Simulation</span>
                            <span style={{ color: "#3C5BFF" }}>{listScore?.progress?.done}/{listScore?.progress?.total}</span>
                        </div>
                        <Progress percent={listScore?.progress?.percentage} showInfo={false} strokeColor="#3C5BFF" trailColor="#98a9fd" />
                    </div>
                </div>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginBottom: (dimension.width <= 1210) ? 120 : 40 }} align="middle">
                <div className="card" style={{ minWidth: "100%", justifyContent: "space-between" }}>
                    {listScore.simulasis && listScore?.simulasis.length > 0 ?
                        listScore?.simulasis.map((item) => (
                            <div
                                style={{ width: "100%", display: "flex", justifyContent: "space-between", height: 80, marginBottom: 30, cursor: "pointer" }}
                                onClick={() => {
                                    navigate(`/subject/${item.mata_pelajaran_id}/learning-simulation/${item.id}`);
                                }}
                            >
                                <div style={{ width: (dimension.width > 900) ? "5%" : "15%", margin: "auto", textAlign: "center" }}>
                                    {item.cover_url && item.cover_url !== "https://dev.alirsyadbandung.sch.id/" ?
                                        <img src={`${item.cover_url}`} alt="Cover"
                                            style={{ cursor: "pointer", width: sizeImage, height: sizeImage, marginLeft: "auto", marginRight: "auto" }}
                                        />
                                        :
                                        <img src="/image/LearningSubject.png" alt="Achievement"
                                            style={{ cursor: "pointer", width: sizeImage, marginLeft: "auto", marginRight: "auto" }}
                                        />
                                    }

                                </div>
                                <div className="achievement"
                                    style={{ width: (dimension.width > 900) ? "90%" : "80%", margin: "auto", borderBottom: "solid 2px #EDEDED" }}
                                >
                                    <div style={{ display: "block" }}>
                                        <div style={{ fontWeight: "bold" }}>
                                            {item.name}
                                        </div>
                                        <div>{item.title}</div>
                                        <div style={{ color: "#9E9E9E" }}>
                                            {item.last_played ?
                                                moment(item.last_played).format("dddd, DD MMMM YYYY")
                                                :
                                                moment(item.created_at).format("dddd, DD MMMM YYYY")
                                            }
                                        </div>
                                        <div style={{ width: "100%", margin: "auto", marginTop: 10, marginBottom: 5 }}>
                                            <div style={{ fontWeight: 500, display: "flex", justifyContent: "space-between" }}>
                                                <span>Your Score</span>
                                                <span style={{ color: "#E98A15" }}>{parseInt(item.rata_rata_score)}/{100}</span>
                                            </div>
                                            <Progress percent={item.rata_rata_score} showInfo={false} strokeColor="#FFAB05" trailColor="#ffde9d" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                        :
                        <div className="card" style={{ display: "block", textAlign: "center", padding: 20 }}>
                            <img src={"/image/Empty.png"} alt="More"
                                style={{ display: "block", cursor: "pointer", marginLeft: "auto", marginRight: "auto", width: 250, height: 200 }}
                            />
                            <div style={{ fontWeight: "bold", fontSize: 25 }}>
                                No Achievement for this Subject
                            </div>
                            <div>
                                You've no achievement for this subject <br />
                                Start your simulation and see the progress
                            </div>
                        </div>
                    }
                </div>
            </Row>
        </div>
    );
}

export default (Achievement)