import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Row, Collapse } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { APIServices } from "../services";
import { Navbar } from "../components/NavBar";
import useWindowDimensions from "../utils/size-window";
import Loading from "../components/LoadingScreen";

const { Panel } = Collapse;

const LearningSimulation = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20
    const [isLoading, setIsLoading] = useState(true);

    const [subjectID, setSubjectID] = useState();
    const [dataSubject, setDataSubject] = useState({});
    const [listSimulation, setListSimulation] = useState([]);

    const data = JSON.parse(localStorage.getItem('data'))

    const getDetailMapel = async (id) => {
        await APIServices.getMapelDetail(id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setDataSubject(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    const getListSimulation = async (mapel_id) => {
        await APIServices.getSimulation(mapel_id)
            .then(res => {
                let response = res.data;
                if (response) {
                    setListSimulation(response.data);
                }
                setIsLoading(false);
            })
            .catch(err => {
                console.log(err);
            })
    }

    useEffect(() => {
        let loc_split = location.pathname.split('/')
        let id = loc_split[2]
        setSubjectID(id);
        getDetailMapel(id);
        getListSimulation(id);
    }, [location])

    return (
        <div style={{ background: "#FFF" }}>
            {dimension.width > 1200 ?
                <>
                    <Navbar />
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "start" }}>
                        <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}`) }} />
                        <span>Back to Subject Detail</span>
                    </Row>
                    <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                        <span style={{ fontSize: 20, fontWeight: 700, marginBottom: 20 }}>{dataSubject?.name || "Learning Simulation"}</span>
                    </Row>
                </>
                :
                <Row>
                    <div className="topbar detail" style={{ width: "100%", justifyContent: "space-between" }}>
                        <div style={{ display: "flex", paddingLeft: 20 }}>
                            <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/subject/${subjectID}`) }} />

                            <div>
                                {dataSubject?.name || "Learning Simulation"}
                            </div>
                        </div>

                        <div className={`badge ${data?.jenjang.toLowerCase()}`} style={{ display: "flex", alignItems: "center", marginRight: 20 }}>
                            {data?.is_pengunjung ?
                                <span>
                                    Visitor
                                </span>
                                :
                                <span>
                                    Class of {(data?.tingkat === "1") ? "1st" : (data?.tingkat === "2") ? "2nd" : (data?.tingkat + "th")}
                                </span>
                            }
                        </div>
                    </div>
                </Row>
            }

            {isLoading ?
                <Loading isChildren={true} />
                :
                <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: (dimension.width <= 1200) && 128 }}>
                    <div style={{ minWidth: "100%" }}>
                        <Collapse accordion expandIconPosition="right">
                            {listSimulation && listSimulation.map((item, index) => (
                                <Panel key={index.toString()}
                                    header={
                                        <>
                                            {item.cover_url && item.cover_url !== "https://dev.alirsyadbandung.sch.id/"
                                                ?

                                                <div className="learning-type">
                                                    <img src={`${item.cover_url}`} alt="Cover"
                                                        style={{ cursor: "pointer", width: 60, height: 60 }}
                                                    />
                                                    <div style={{ marginLeft: 40 }}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                                :
                                                < div className="learning-type">
                                                    <img src="/image/LearningSubject.png" alt="Learning Simulation" />
                                                    <div style={{ marginLeft: 40 }}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            }
                                        </>
                                    }
                                    collapsible={(!item.is_public && !item.mapel_assigned  && data.is_pengunjung) && "disabled"}
                                >
                                    {/* {item.subbab && item.subbab.map(video => ( */}
                                    <div onClick={() => { navigate(`/subject/${subjectID}/learning-simulation/${item.id}`) }}
                                        style={{ borderBottom: "solid 1px #EDEDED", marginLeft: 70, height: 40, cursor: "pointer" }}>
                                        {item.name}
                                    </div>
                                    {/* ))} */}
                                </Panel>
                            ))}
                        </Collapse>
                    </div>
                </Row>
            }
        </div >
    );
}

export default (LearningSimulation)