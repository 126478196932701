import React, { useState } from "react";
import { Row, Input } from 'antd';
import {
    ArrowLeftOutlined,
} from '@ant-design/icons';
import { Formik, Form } from 'formik';
import Auth from "../services/Auth";
import { ModalLogout } from "../components/Modal";
import useWindowDimensions from "../utils/size-window";
import { useNavigate } from "react-router-dom";

const PersonalInfo = (props) => {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const dimension = useWindowDimensions();
    let marginValue = (dimension.width > 800) ? 60 : 20;

    return (
        <div style={{background:"#FFF"}}>
            <ModalLogout visible={showModal} setShowModal={setShowModal} logout={Auth.logout} />
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 128, justifyContent: "flex-start" }}>
                <ArrowLeftOutlined style={{ marginRight: 20, paddingTop: 3 }} onClick={() => { navigate(`/profile`) }} />
                <span>Back to Profile</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent: "center" }}>
                <span style={{fontWeight: "bold", fontSize: 20}}>Personal Information</span>
            </Row>
            <Row style={{ marginLeft: marginValue, marginRight: marginValue, marginTop: 20, justifyContent:"center" }}>
                <div style={{ width: "100%", maxWidth: 600 }}>
                    <Formik
                        initialValues={{
                            nis: props.data?.nis,
                            password: "password",
                            email: props.data?.email,
                            name: props.data?.name,
                            class: props.data?.is_pengunjung ? props.data?.jenjang + " (Visitor)" : props.data?.jenjang + " - KELAS - " + props.data?.tingkat + props.data?.kelas
                        }}
                    >
                        {({ isSubmitting, values, setFieldValue }) => (
                            <Form>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>NIS</span>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="NIS"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="nis"
                                        value={values.nis}
                                        disabled
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                    />
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Fullname</span>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Fullname"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="name"
                                        value={values.name}
                                        disabled
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                    />
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Email</span>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Email"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="email"
                                        value={values.email}
                                        disabled
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                    />
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Password</span>
                                    <Input.Password
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Password"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="password"
                                        value={values.password}
                                        disabled
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                    />
                                </Row>
                                <Row style={{ marginBottom: 10 }}>
                                    <span style={{ fontWeight: "bold" }}>Active Class</span>
                                    <Input
                                        // prefix={<MailOutlined />}
                                        type="text"
                                        placeholder="Active Class"
                                        style={{ width: "100%", padding: 10, borderRadius: 5 }}
                                        name="class"
                                        value={values.class}
                                        disabled
                                        autoComplete="off"
                                        onChange={(e) => { setFieldValue("nis", e.target.value) }}
                                    />
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Row>
        </div>
    );
}

export default (PersonalInfo)